.MainContent {
  padding: 7rem 0.5rem;
  width: 100%;
}

.schedule {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.2);
}
.scheduleHeader {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.scheduleHours {
  background-color: rgb(28, 34, 47);
  display: flex;
  justify-content: center;
  width: 5.5rem;
  color: #fff;
}

.scheduleDays {
  background-color: rgb(28, 34, 47);
  display: flex;
  width: 100%;
  color: #fff;
}

.scheduleDays > div {
  min-width: 16.666666667%;
  text-align: center;
}

.scheduleColumn {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.scheduleCell {
  min-height: 31.5rem;
  display: flex;
  flex-wrap: wrap;
}

.scheduleColumn:nth-child(2) {
  color: #c4161c;
  line-height: 31rem;
  text-align: center;
  display: flex;
  width: 5.5rem;
  font-size: 2rem;
  background-color: #fff;
  border-left: 2px solid #e1e1e1;
}
.scheduleColumn:nth-child(2) p {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.scheduleColumn:not(:nth-child(2)) {
  width: calc((100% - 5.5rem)/6);
  font-size: 1px;
}

@media (max-width: 1710px) {
  .scheduleColumn:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1520px) {
  .scheduleColumn:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1370px) {
  .scheduleColumn:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1260px) {
  .scheduleColumn:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}
@media (max-width: 1040px) {
  .scheduleColumn:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

.scheduleColumn:not(:nth-child(2)) .scheduleCell {
  padding: 3px;
}

.scheduleHeader {
  text-align: center;
  font-size: 2rem;
}

.scheduleHeader .scheduleHours {
  align-items: center;
  text-align: center;
  width: 5.5rem;
}

.schedule {
  background-color: white;
  border: 1px solid #e1e1e1;
}

.scheduleHeader > div {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
}

.scheduleCell {
  border: 0.1px solid #e1e1e1;
}

@media (min-width: 1326px) {
  .scheduleMobile {
    display: none;
  }
}

@media (max-width: 1325px) {
  .schedule {
    display: none;
  }
  .scheduleMobile {
    display: flex;
    flex-direction: column;
  }
}
/*mobile/tab shedule*/

.scheduleMobileDays {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0rem 6rem rgba(0, 0, 0, 0.2);
}
.scheduleMobileDay {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  color: #c4161c;
  padding: 5rem 0rem;
}
.scheduleMobileListContainer {
  width: 100%;
  
}
.scheduleMobileList {
  list-style: none;
}
.scheduleMobileListItem {
  width: 100% !important;
}
