.IndividualClasses {
    margin: 0 auto;
  }
  
  .IndividualClasses h1 {
    text-align: center;
  }
  .mainSection {
    text-align: center;
    padding: 10% 14%;
    font-size: 2.5rem;
    font-family: "Poppins", sans-serif;
  }
  
  .mainSection p:first-child {
  
    font-size: 3rem;
    margin-bottom: 6rem;
  }
  
  .mainSection p:not(:first-child) {
    margin-bottom: 4rem;
  }
  
  .highlighted {
    color: rgba(196, 22, 28, 1);
  }