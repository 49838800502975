.Contact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Poppins", sans-serif;
}

.mainSection {
  display: flex;
  flex-wrap: wrap;
}

.map {
  background-image: linear-gradient(
    to left top,
    rgb(70, 89, 129),
    rgb(28, 34, 47)
  );
  width: 100%;
  order: 1;
}

.text {
  width: 100%;
  background-color: rgb(249, 249, 249);
  z-index: 1;
  order: 0;
}

.form {
  width: 100%;
  height: auto;
  background-color: #c4161c;
  box-shadow: 0rem 0rem 8rem rgba(0, 0, 0, 0.3) inset;
}

.text {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.text ul {
  list-style-type: none;
}

.text ul {
  padding: 16rem 0;
}

@media (max-width: 75em) {
  .text {
    justify-content: center;
  }
  .text ul {
    padding: 8rem 4rem;
    text-align: center;
  }
}

.text ul li:first-child {
  font-size: 3rem;
  padding-bottom: 3rem;
}

.text ul:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.text ul:nth-child(2) li {
  display: flex;
  justify-content: space-between;
}

.redText {
  color: #c4161c;
}

@media (max-width: 56.25em) {
  .logo {
    top: 5%;
    left: 5%;
    transform: scale(0.2);
  }
}

.sectionBook {
  color: #fff;
  padding: 15rem 28rem;
}
@media (max-width: 75em) {
  .sectionBook {
    padding: 8rem 10rem;
  }
}
@media (max-width: 56.25em) {
  .sectionBook {
    padding: 0;
  }
}
