.section {
  width: auto;
  height: 100%;
  text-align: center;
  position: relative;
  background-color: rgba(196, 22, 28, 1);
  box-shadow: 0rem 0rem 8rem rgba(0, 0, 0, 0.5) inset;
  padding-bottom: 7rem;
}

.text {
  position: relative;
  text-align: center;
  padding: 13rem 0;
  padding-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 5.5rem;
  font-weight: 500;
  color: #fff;
  z-index: 2;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    209deg,
    rgb(28, 34, 47) 49.5rem,
    transparent 49.5rem
  );
  z-index: 1;
  display: none;
}

.instructorSlider {
  padding: 5rem;
}
