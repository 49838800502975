.Instructors {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(249, 249, 249);
  height: auto;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.headerName {
  text-shadow: 0 0 .8rem rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 30%;
  left: 65%;
  font-size: 8rem;
  color: #fff;
  z-index: 20;
  animation: moveInRight 0.8s ease-out;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 75em) {
  .Instructors {
    flex-wrap: wrap;
  }
  .headerName {
    left: 60%;
    top: 52%;
    font-size: 7rem;
  }
}
@media (max-width: 56.25em) {
  .headerName {
    font-size: 7rem;
    left: 10%;
    bottom: unset;
    top: 1%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}
