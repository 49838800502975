.footer {
  background-color: rgb(28, 34, 47);
  padding: 10rem 0;
  font-size: 1.8rem;
  color: rgb(221, 221, 221);
  font-family: "Poppins", sans-serif;
}

.footer h3 {
  font-size: 2.3rem;
  font-weight: 500;
}

.logoBox {
  text-align: center;
  margin-bottom: 7rem;
}

.logo {
  width: 15rem;
  height: auto;
}

.navigation {
  margin-top: 3rem;
  border-top: 2px solid rgb(168, 15, 15);
  margin-left: 1rem;
}

.list {
  list-style: none;
  width: 400px;
  padding-right: 0.1rem;
  padding-top: 0.2rem;
  display: inline-block;
  
  font-weight: 200;
}

.item {
  transition: all 0.2s;
}

.item:hover {
 
}

.item:not(:last-child) {
  margin-right: 1.5rem;
}

.item a {
  color: rgb(221, 221, 221);
  text-decoration: none;
  transition: all 0.1s;
}

.item a:hover {
  color: #c4161c;
  font-size: 2rem;
  font-weight: 400;
}
.copyright {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.ulist ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 400px;
  font-weight: 200;
}

.ulist ul li {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.iframeMedia {
  margin: 5rem 0;
}


@media (max-width: 52em) {
  .list {
    width: 265px;
  }

  .ulist{
    width: fit-content;
  }
  .ulist ul {
    width: 265px;
  }
  .row {
    
    font-size: 1.8rem;
  }
  .row > div {
    display: flex;
    
    justify-content: center;
  }
 
  .footer h3 {
    font-size: 2.2rem;
    font-weight: 500;
  }
}

@media(max-width: 562px) {
   .row > div:not(:first-child) {
    margin-top: 5rem;
  }
}


.iframeContent {
  display: flex;
  justify-content: center;
  width: 100%;
}
