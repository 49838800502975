.BatchParty {
    margin: 0 auto;
  }
  
  .BatchParty h1 {
    text-align: center;
  }
  
.mainContent {
  font-family: "Poppins", sans-serif;
  padding: 5% 15%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  justify-content: center;
  text-align: left;
  font-size: 1.5rem;
}

.mainContent h3 {
 color:rgba(196, 22, 28, 1);
 font-size: 3.3rem;
 text-align: center;
}

.mainContent p {
  font-size: 2.4rem;
  margin-top: 2rem;
  display: inline-block;
}


.image {
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.image img {
  width: 600px;
  height: auto;
  box-shadow: 1rem 1rem 3rem rgba(0,0,0,.4);
  outline: 1rem solid black;
  outline-offset: 2.5rem
}


@media (max-width: 75em) {
  .image img {
    width: 500px;
  }
}
@media (max-width: 56.25em) {
  .image img {
    width: 100%;
  }
}
