.header {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.textBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  margin-top: 12vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 5rem;
  height: auto;
}

.textDance {
  font-weight: 500;
}

@media (max-width: 40em) {
  .text {
    margin-top: 8rem;
  }
}
