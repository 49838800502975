.Prices {
  margin: 0 auto;
  background-color: rgb(249, 249, 249);
  font-family: "Poppins", sans-serif;
}

.Prices h1 {
  text-align: center;
}

.Prices h3 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
}

.Prices h2 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}
.Prices h4 {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 7rem;
}

.mainHeading {
  padding: 10rem 0;
}

.paddingy {
  padding-left: 10rem;
  padding-right: 10rem;
}

.carnets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.danceWithInstructor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;
}

.roomRental {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;

}

.proAmator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;

}

.partners {
  padding: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.partners h3 {
  padding: 5rem;
  width: 100%;
}

.partners p {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 2rem;
}

.partners img {
  width: auto;
  height: 12rem;
}

.price {
  color: #c4161c;
}

.studentHall {
  padding: 10rem;
}