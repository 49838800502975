.CardMobile {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 1.8rem;
  height: 100%;
  background-color: inherit;
  position: relative;
  word-break: keep-all;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s;
  width: 100%;
  margin: 1rem 0;
}
@media (max-width: 810px) {
  .CardMobile {
    font-size: 1.5rem;
  }
}
.informations {
  width: 30%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  padding: 1rem;
}
.CardMobile a {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  width: 15%;
  height: 100%;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  text-align: center;
  align-self: center;
}
.btn {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 1.3rem;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.35s;
}
.content {
  width: 70%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.content div:first-child {
  width: 48%;
  align-self: center;
}
.content div:nth-child(2) {
  width: 22%;
  line-height: 5rem;
  align-self: center;
}
.content div:nth-child(3) {
  width: 30%;
  align-self: center;
}
.content div:nth-child(4) {
  width: 25%;
  align-self: center;
}

.content > div.link {
  padding: 1rem;
  align-self: center;
}

.content > div:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 655px) {
  .content div p {
    display: inline-block;
    vertical-align: middle;
  }
}

@media(max-width:518px) {
  .content div p {
    font-size: 1.3rem;
  }
  .informations {
    width: 30%;
  }
  .content div:nth-child(3) {
    width: 35%;
  }
}

.btn a,
.btn a:active,
.btn a:link {
  color: #fff;
  border: none;
}
.CardMobile .btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.CardMobile:hover .btn {
  background-color: rgba(255, 255, 255, 0.2);
}

.CardMobile:hover .link {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.CardMobile .link:hover {
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
