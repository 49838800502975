.Navigation {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;

  display: none;
}
@media (max-width: 75em) {
  .Navigation {
    display: initial;
  }
}
@media (max-width: 56.25em) {
  .Navigation {
    display: initial;
  }
}
@media (min-width: 112.5em) {
  .Navigation {
    display: none;
  }
}

.checkbox {
  display: none;
}

.label {
  background-color: rgb(28, 34, 47);
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.3);
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 2px;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 20000;
  text-align: center;
  cursor: pointer;
}

.background {
  height: 0rem;
  width: 0rem;
  border-radius: 1px;
  position: fixed;
  top: 0rem;
  right: 0rem;
  background-image: radial-gradient(rgb(28, 34, 47), rgb(28, 34, 47));
  opacity: 0;
  z-index: 10000;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 0.55),
    opacity 0.5s cubic-bezier(0.86, 0, 0.07, 0.55);
}

.container {
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-image: inherit;
  opacity: 0;
  width: 0;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.container > ul.list {
  display: none;
}

.list {
  list-style: none;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}

.item {
  margin: 1rem;
}

.link:link,
.link:visited {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;
}

.link:link span,
.link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}

.link:hover,
.link:active,
link.active {
  background-position: 100%;
  color: #c4161c;
  transform: translateX(1rem);
}

.dropdownList > ul a {
  font-size: 1.6rem !important;
}

.dropdownList ul {
  list-style: none;
}

button.navButton:hover + .dropdownList {
  background-color: #fff;
  display: none;
}

button.navButton:focus {
  border: none;
}

.navButton {
  display: inline-block;
  background-color: inherit;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 300;
  padding: 1.1rem 2rem;
  border: none;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.4s;
}

.checkbox:checked ~ .background {
  z-index: 1000;
  opacity: 1;
  height: 100vh;
  width: 100%;
}

.checkbox:checked ~ .container {
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.checkbox:checked ~ .container > ul.list {
  display: inline-block;
}

.icon {
  width: 3rem;
  position: relative;
  margin-top: 2.2rem;
}

.icon,
.icon::before,
.icon::after {
  height: 0.25rem;
  background-color: #fff;
  display: inline-block;
}

.icon::after {
  width: 1.8rem;
}

.icon::before {
  width: 2.6rem;
}

.icon::before,
.icon::after {
  content: "";
  position: absolute;
  right: 0;
  backface-visibility: hidden;
  transition: all 0.2s;
}

.icon::before {
  top: -0.8rem;
}

.icon::after {
  top: 0.8rem;
}

.label:hover .icon::before {
  top: -0.9rem;
}

.label:hover .icon::after {
  top: 0.9rem;
}

.checkbox:checked + .label .icon {
  background-color: transparent;
}

.checkbox:checked + .label .icon::before {
  transform: rotate(135deg);
  width: 2.8rem;
  top: 0;
}

.checkbox:checked + .label .icon::after {
  transform: rotate(-135deg);
  width: 2.8rem;
  top: 0;
}

.logo {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
