.HeaderImage {
  width: 35%;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.navi {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 31rem;
  height: 15rem;
  line-height: 17rem;
  display: flex;
  align-content: stretch;
}

.arrowLeft {
  background-color: rgb(28, 34, 47) !important;
  width: 15rem;
  height: 15rem;
  font-size: 13rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s;
}

.arrowRight {
  background-color: rgb(28, 34, 47) !important;
  width: 16rem;
  height: 15rem;
  border-left: 1px solid black;
  cursor: pointer;
  font-size: 13rem;
  text-align: center;
}

.arrowLeft:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

.arrowRight:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

@media (max-width: 75em) {
  .HeaderImage {
    width: 40%;
  }
}
@media (max-width: 56.25em) {
  .HeaderImage {
    width: 100%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}
