.HomePage {
  display: block;
}

.mediaContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem;
  justify-content: space-evenly;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 0rem 5rem rgba(0, 0, 0, 0.165) ;
}
.iframeMedia {
  margin: 1rem;
  border: 1px solid black;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
}

@media (max-width: 56.25em) {
  .mediaContainer {
    padding: 4rem;
  }
}
