.heading {
  font-size: 2rem;
  padding: 2rem 0;
}

.headingSecondary {
  font-weight: 200;
}

/* rgb(28, 34, 47); */

.book {
  background-color: rgb(28, 34, 47);
  background-size: cover;
  border-radius: 2rem;
  box-shadow: 0rem 1rem 4rem rgba(28, 34, 47, 0.767);
}



.bookForm {
  width: 100%;
  padding: 2rem 6rem;
  position: relative;
  overflow-y: auto;
}



.formGroup {
  margin-bottom: 2rem;
}

.formInput {
  font-size: 1.5rem;
  color: inherit;
  font-family: inherit;
  padding: 1rem 1rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.4s;
}



.formInput::-webkit-input-placeholder {
  color: rgb(49, 49, 49);
}
.formInput:focus:invalid {
  border-bottom: 3px solid #ff5638;
}
.formInput:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}

.formLabel {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
  font-weight: 300;
  margin-left: 0.3rem;
  display: block;
  transition: all 0.3s;
}

.formInput:placeholder-shown + .formLabel {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.btn,
.btn:link,
.btn:visited {
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  text-decoration: none;
  padding: 1rem 3rem;
  display: inline-block;
  border-radius: 2px;
  transition: all 0.2s;
  position: absolute;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border: none;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.2s;
}
.btnRed {
  font-weight: 500;
  border: 2px solid rgb(243, 38, 45);
  background-color: transparent;
  color: rgb(243, 38, 45);
}
.btnRed::after {
  background-color: rgb(243, 38, 45);
  color: rgb(28, 34, 47);
  z-index: -1;
}

.btnRed:hover {
  background-color: rgb(243, 38, 45);
  color: rgb(28, 34, 47);
}

p.instructions {
  color: rgba(255, 255, 255, 1);
  position: absolute;
  font-weight: 200;
  font-size: 1.7rem;
  top: -1rem;
  display: block;
  right: 0;
  width: 70%;
  text-align: center;
}




@media (max-width: 56.25em) {
  .formInput {
    width: 100%;
  }
}

@media (max-width: 37.5em) {
  .book {
    box-shadow: none;
    border-radius: 0;
  }

  .bookForm {
    padding: 2rem 3rem;
  }
}