.popup {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: stretch;
  top: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 0.9);
  z-index: 999999;
  opacity: 1;
  transition: all 0.5s;
  
}
@media (max-width: 56.25em) {
  .popup {
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .popup {
    height: 100%;
    display: flex;
  }
}
.popupContent {
  width: 100%;
}

.popupClose {
  position: fixed;
  color: #999;
  background-color: transparent;
  width: 6rem;
  height: 6rem;
  right: 1rem;
  top: 1rem;
  border: none;
  font-size: 5rem;
  text-decoration: none;
  transition: all 0.2s;
  line-height: 1;
  z-index: 12345699;
}



.popupClose:hover {
  color: rgb(243, 38, 45);
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.form {

  font-family: "Poppins", sans-serif;
  color: white;
  position: fixed;
  overflow-y: auto;
  scrollbar-base-color: rgb(28, 34, 47);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 2;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(243, 38, 45); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(28, 34, 47); 
}

@media (max-width: 80.25em) {
  .form {
    top: 50%;
    width: 90%;
    height: 90%;
  }
}
@media (max-width: 37.5em) {
  .form {
    top: 50%;
    width: 100%;
    height: 100%;
  }
}
