.HeaderDescription {
  width: 65%;
  padding-top: 3rem;
  color: rgb(249, 249, 249);
  background-color: #c4161c;
  
  letter-spacing: 1px;
  font-weight: 200;
  display: flex;
}

.content {
  padding: 4rem;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
  display: flex;
  text-align: left;
}

.content p {
  align-self: flex-start;
  animation: moveInLeft 1.2s ease-out;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  60% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 90em){
  .HeaderDescription{
    font-size: 1.4rem;
  }
}

@media (max-width: 75em) {
  .HeaderDescription {
    width: 60%;
    font-size: 1.3rem;
    padding-top: 0;
  }
}
@media (max-width: 56.25em) {
  .HeaderDescription {
    width: 100%;
    order: 1;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}
