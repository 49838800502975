.Dances {
  background-color: rgb(247, 247, 247);
  display: flex;
  width: fit-content;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.mainSection {
  width: 100%;
  height: auto;
  background-color: rgb(221, 221, 221);
  display: flex;
}

.video {
  padding: 1rem;
  width: 50%;
}

.content {
  width: 50%;
}

.nearestClasses {
  padding: 2rem 0;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  height: 100%;
  text-align: center;
}
.nearestClasses p:first-child {
  text-align: center;
  color: #c4161c;
}
.schedule {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.classes {
  margin: 1.2rem;
  padding: 1.2rem;
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.2s;
  border: 1px solid #c4161c;
  border-radius: 3px;
}

.classes:hover {
  transform: scale(1.1);
}

.classes p:not(:first-child) {
  border-top: 1px solid rgba(196, 22, 28, 0.2);
}
.contentHeader {
  font-size: 3rem;
  color: rgb(28, 34, 47) !important;
}

@media (max-width: 80em) {
  .mainSection {
    flex-wrap: wrap;
  }
  .video {
    width: 100%;
  }
  .content {
    width: 100%;
  }
}
