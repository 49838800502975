.panel-enter {
    transform: translate(100%, 0);
    opacity: 0.01;
    transition: transform 1350ms ease-out, opacity 700ms ease-in;
  }
  
  .panel-enter-active {
    transform: translate(0, 0);
    opacity: 1;
  }
  
 
.panel-exit {
    transform: translate(0, 0) ;
    opacity: 1;
  }
  
  .panel-exit-active { 
    transform: translate(-100%, 0) ;
    opacity: 0;
    transition: transform 1500ms ease-in, opacity 700ms ease-out;
  }