.Legend {
  padding: 6rem;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 500px) {
  .Legend {
    padding: 2rem;
  }
}

.dances {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.dances li {
  margin-top: 3rem;
  margin-right: 1rem;
}

.dances li:first-child,
.levels li:first-child {
  width: 100%;
}

.levels {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  list-style: none;
  text-align: justify;
}

.levels li {
  width: 100%;
}

.solo {
  color: #fff;
  padding: 1.2rem;
  background: #f80c12;
}

.inPairs {
  color: #fff;
  padding: 1.2rem;
  background: #1193bb;
}

.danceFormation {
  color: #fff;
  padding: 1.2rem;
  background: #69d025;
}

.kids {
  color: #fff;
  padding: 1.2rem;
  background: #fd61c1;
}

.offer {
  text-align: center;
  padding-top: 15px;
}

@media (max-width: 1164px) {
  .Legend {
    font-size: 1.9rem;
  }
}
