.Slider {
    width: 100%;
    height: 100vh;
}

.slider div {
    width: 100%;
    height: 100vh;
}

.image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}