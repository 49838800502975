@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900,1000,1200");

.Header {
  position: relative;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  background-color: rgb(28, 34, 47);
}

.background {
  display: none;

  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("../../../assets/images/dances/flamenco_mobile_new.jpg");
  background-image: linear-gradient(
      to top,
      rgb(47, 56, 77),
      rgba(255, 255, 255, 0.205)
    ),
    url("../../../assets/images/dances/flamenco_mobile_new.jpg");
  filter: brightness(60%);
}

@media (max-width: 56.25em) {
  .background {
    display: flex;
  }
}
.bgVideoContent {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 56.25em) {
  .bgdesktop {
    display: none;
  }
}
.text {
  position: absolute;
  min-width: 270px;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2.6rem;
  overflow: hidden;
  color: #fff;
}
.text p:nth-child(2) {
  font-size: 4rem;
  font-weight: 600;
}
.text p:last-child {
  margin-top: 5rem;
  font-size: 2.7rem;
}

@media (max-width: 56.25em) {
  .text {
    font-size: 1.5rem;
    text-align: center;
    word-wrap: break-word;

    z-index: 100;
  }

  .text p:last-child {
    margin-top: 4rem;
    font-size: 2rem;
  }

  .text p:nth-child(2) {
    font-size: 3rem;
    font-weight: 600;
  }
}

/* Scroll Button */

.demo a {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  z-index: 100;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  background-color: transparent;

  border-radius: 50%;

  width: 10.9rem;
  height: 10.9rem;

  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);

  transition: opacity 0.3s;
}
.section10 a:hover span {
  opacity: 0.5;
}

.section10 a span {
  position: absolute;
  top: 35px;
  left: 50%;

  width: 30px;
  height: 45px;
  margin-left: -15px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
  transition: opacity 0.3s;
}

.section10 a span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}

.section10 a span:hover {
  opacity: 0.5;
}

.section10 a span:hover ~ .arrow {
  opacity: 0.5;
}

.arrow {
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 100;
  font-size: 2rem;
  z-index: 30;
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.bgdesktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgb(28, 34, 47) 4%,
    transparent 60%);
  /* ), url("../../../assets/images/homepageHeaderDesktop.jpg"); */
 background-position: center;
  background-size: cover;
  z-index: 1;
}

.slider {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}