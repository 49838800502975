.DesktopNavigation {
  background-image: linear-gradient(
    to right,
    rgba(28, 34, 47, 1) 100%,
    transparent 100%
  );
  height: 60px;
  position: absolute;
  top: 0rem;
  left: 0;
  width: 100%;
  z-index: 122;
  list-style: none;
  position: fixed;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -2rem 5rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 75em) {
  .DesktopNavigation {
    display: none;
  }
}
@media (max-width: 56.25em) {
  .DesktopNavigation {
    display: none;
  }
}
@media (min-width: 112.5em) {
  .DesktopNavigation {
    display: flex;
  }
}

ul.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.list li {
  display: flex;
  line-height: 60px;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  padding: 0 2rem;
}

.list li a {
  color: rgb(230, 230, 230);
  text-decoration: none;
  backface-visibility: hidden;
  height: 100%;
  padding: 0 1rem;
  transition: border-bottom 0.01s ease-in-out;
}

.list li a.active {
  font-weight: 600;
  border-bottom: 3px solid rgba(196, 22, 28, 1);
  color: rgba(196, 22, 28, 1);
}

.list li a:hover {
  border-bottom: 3px solid rgba(196, 22, 28, 1);
}

.dropdown a.firstChild {
  border-bottom: 1px solid rgb(28, 34, 47);
}

.dropdownContent {
  opacity: 0;
  width: fit-content;
  font-size: 1.2rem;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  position: absolute;
  background-color: rgb(28, 34, 47);
  
  left: 0;
  backface-visibility: hidden;
  transition: all 0.2s;
}

.dropdownContent a:not(:first-child) {
  margin-left: 2rem;
}

.dropdown {
  width: fit-content;
  transition: all 0.2s;
}

.dropdown:hover .dropdownContent {
  opacity: 1;
  width: fit-content;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  position: absolute;
  left:0rem;
  backface-visibility: hidden;
  transition: all 0.2s;
}

.logoBox {
  position: absolute;
  width: auto;
  height: fit-content;
  right: 0;
  top: 55%;
  transform: translate(-50%, -50%);
}
.logoBox img {
  width: 8rem;
}