.solo {
  background-color: #f80c12 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(248, 12, 20, 0.2);
}

.inPairs {
  background-color: #1193bb !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(17, 147, 187, 0.2);

}

.danceFormation {
  background-color: #69d025 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(105, 208, 37, 0.2);

}

.kids {
  background-color: #fd61c1 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(253, 97, 193,0.2);

}

@media (max-width: 1325px) {
  .danceFormation,
  .inPairs,
  .solo,
  .kids {
    border-radius: 3px;
    margin: 0;
    height: 100%;
  }
}
