.Card {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  color: #fff;
  font-size: 1.2rem;
  height: 100%;
  background-color: inherit;
  position: relative;
  transition: all 0.2s;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s;
  width: 100%;
}

.Card:hover {
  transform: scale(1.1);
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.Card > *:first-child {
  border-left: none;
}

.informations {
  text-transform: capitalize;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0.7rem 25rem rgba(255, 255, 255, 0.65) inset;
  filter: brightness(110%), contrast(120%);
  background-color: inherit;
  position: absolute;
  word-wrap: break-word;
  padding: 1.4rem 0.2rem;
  font-size: 1rem;
  width: 100%;
  left: 0rem;
  top: -7rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
  height: 6.5rem;
  z-index: 1;
  transition: opacity 0.3s;
}
.Card:hover .informations {
  visibility: visible;
  opacity: 1;
}
.Card a {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  width: 100%;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
}
.btn {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  width: 100%;
  height: 23%;
  line-height: 2.7rem;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.35s;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}
.content > * {
  transition: all 0.2s;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn a,
.btn a:active,
.btn a:link {
  color: #fff;
  border: none;
}
.Card .btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.Card .content div:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.Card:hover .btn {
  background-color: rgba(255, 255, 255, 0.2);
}
.Card:hover .link {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.Card .link:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.danceName {
  line-height: auto;
  font-size: 1.3rem;
  word-wrap: break-word;
}
@media (max-width: 1490px) {
  .danceName {
    font-size: 1.15rem;
  }
  .btn {
    font-size: 1.1rem;
  }
}
.smallContainer {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  background-color: inherit;
}
