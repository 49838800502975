.DanceShows {
    margin: 0 auto;
  }
  
  .DanceShows h1 {
    text-align: center;
  }
.mainSection {
  padding: 10% 14%;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
}

.mainSection p:first-child {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 6rem;
}

.mainSection p:not(:first-child) {
  margin-bottom: 4rem;
}

.highlighted {
  color: rgba(196, 22, 28, 1);
}

.mainSection .images {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.images div {
  width: 350px;
  margin-top: 5rem;
}

.images div:not(:first-child){
  margin-left: 2rem;
 
}

.images div img {
  box-shadow: 2px 2px 15px #ccc; 
  border-radius: 3px;
  outline: solid 0.5rem black;
  outline-offset: 1rem;
}
  