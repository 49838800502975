.Header {
  height: 100vh;
  background-color: rgb(209, 209, 209);
  display: flex;
  flex-wrap: wrap;
  backface-visibility: hidden;
  position: relative;
}

.containerLeft {
  position: relative;
  width: 20%;
  background-color: rgb(28, 34, 47);
}

.mainText {
  position: absolute;

  top: 5rem;
  left: 10rem;
  font-size: 4rem;
  text-transform: capitalize;
  color: #fff;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  padding-left: 1rem;
  padding-top: 2rem;
  line-height: 16rem;
  z-index: 10;
}

h1 {
  font-weight: 500;
  animation-name: moveInLeft;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
}

.containerMiddle {
  width: 50%;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.containerRight {
  width: 30%;
  background-color: #c4161c;
  color: #fff;
  padding: 1rem;
  align-self: stretch;
  display: flex;
}

.description {
  height: 100%;
  width: 100%;
  text-align: left;
  line-height: 1.5;
  padding: 2rem;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
}

.description div {
  align-self: flex-end;
  font-weight: 200;

  animation-name: moveInRight;
  animation-duration: 1.8s;
  animation-timing-function: ease-out;
}

.navi {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  line-height: 17rem;
  display: flex;
  align-content: stretch;

  z-index: 10;
}

.arrowLeft {
  background-color: rgba(0, 0, 0, 0.1) !important;
  width: 50%;
  height: 15rem;
  font-size: 13rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s;
  z-index: 10;
}

.arrowRight {
  background-color: rgba(0, 0, 0, 0.1) !important;
  width: 50%;
  height: 15rem;
  border-left: 1px solid black;
  cursor: pointer;
  font-size: 13rem;
  text-align: center;
  z-index: 10;
}

.arrowLeft:hover {
  color: rgb(28, 34, 47);
  background-color: rgb(196, 22, 28) !important;
}

.arrowRight:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  20% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  70% {
    opacity: 0;
    transform: translateX(10rem);
  }

  90% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 80em) {
  .Header {
    height: auto;
  }
  .mainText {
    top: 20rem;
    left: 1rem;
    font-size: 3.6rem;
  }
  .containerLeft {
    width: 30%;
  }
  .containerMiddle {
    width: 70%;
    background-position: center;
  }
  .containerRight {
    width: 100%;
  }
}
@media (max-width: 56.25em) {
  .Header {
    height: auto;
  }
  .mainText {
    
    border: none;
    top: 65rem;
    height: 10rem;
    line-height: 5rem;
    color: #fff;
    left: 0;
    width: 100%;
    font-size: 2rem;
    word-wrap: break-word;
    letter-spacing: normal;
  }
  .containerLeft {
    position: relative;
    width: 100%;
    height: 20vh;

    order: 1;
    z-index: 100;
  }
  .containerMiddle {
    order: 0;
    height: 80vh;
    width: 100%;
    background-position: center;
  }
  .containerRight {
    width: 100%;
    order: 2;
  }
  .navi {
    line-height: 22vh;
    height: 100%;
  }
  .arrowLeft {
    height: 100%;
  }
  .arrowRight {
    height: 100%;
  }
}
