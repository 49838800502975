.About {
  position: relative;
  display: flex;
  height: auto;
  background-color: rgb(28, 34, 47);
  
  color: rgb(240, 240, 240);
}

.containerLeft {
  padding: 10rem 10rem;
  width: 55%;
  height: auto;
  
  /* background-color: rgb(249, 249, 249); */
  /* box-shadow: 1rem 2rem 5rem rgba(0, 0, 0, 0.15); */
}

.containerLeft h2 {
  font-family: "Poppins", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 3rem;
  padding-left: 1rem;
  border-left: 2px solid rgb(240, 240, 240);
}

.containerLeft p {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 1.55rem;
  text-align: justify;
}

.highlighted {
  letter-spacing: 1px;
  font-weight: 600;
  display: inline-block;
  padding: 2px;
  margin: 2px;
  transition: all 0.2s;
}

.highlighted:hover {
  color: #c4161c;
  border-bottom: 2px solid #c4161c;
}

.containerRight {
  position: relative;
  width: 65%;
  padding: 35rem 10rem;
  /* box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.1) inset; */
}
.containerRight img {
  width: 50%;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.3);
  outline: .6rem solid #fff;
  outline-offset: 1rem;
  filter: contrast(1.1);
}

.highlighted a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.highlighted a:hover {
  color: #c4161c;
}

.image2 { 
  position: absolute;
  bottom: 6rem;
  right: 10rem;
  transition: all 0.2s;
}

.image1 {
  position: absolute;
  top: 6rem;
  left: 10rem;
  transition: all 0.2s;
}
.image1:hover,
  .image2:hover {
    transform: scale(1.05) translateY(-0.5rem);
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    z-index: 20;
  }

@media (max-width: 80em) {
  .About {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .containerLeft {
    width: 100%;
    padding: 12rem;
  }
  .containerRight {
    width: 100%;
  }

  .containerRight img {
    width: 45%;
  }
}
@media (max-width: 56.25em) {
  .containerLeft {
    padding: 3.2rem;
  }
  .containerRight {
    position: relative;
    transition: all 0.2s;
  }
  .containerRight img {
    width: 55%;
  }

  .image1 {
    top: 11rem;
    position: absolute;
    left: 7rem;
    height: auto;
    transition: all 0.2s;
  }
  .image2 {
    bottom: 11rem;
    position: absolute;
    right: 7rem;
    transition: all 0.2s;
  }

  .image1:hover,
  .image2:hover {
    transform: scale(1.1) translateY(-0.5rem);
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    z-index: 20;
  }
}

@media (max-width: 32.5em) {
  .containerRight {
    position: relative;
    transition: all 0.2s;
  }
  .image1,
  .image2 {
    width: 67% !important;
  }
}

@media (min-width: 112.5em) {
}
