/*

0 - 600px       phone
600 - 900px     tablet portrait
900 - 1200px    tablet landscape
1200 - 1800     normal styles
1800px +        big desktop

breakpoints
600px bo 1em = 16px => 
- phone         max-width 37.5em
- tab-port      max-width 56.25em
- tab-land      max-width 75em
- big-desktop   min-width 112.5em

*/

*,
*::after,
*::before {
  margin: 0 ;
  padding: 0 ;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(249, 249, 249);
  color: #777;
}