@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900,1000,1200);
.Footer_footer__2x5FD {
  background-color: rgb(28, 34, 47);
  padding: 10rem 0;
  font-size: 1.8rem;
  color: rgb(221, 221, 221);
  font-family: "Poppins", sans-serif;
}

.Footer_footer__2x5FD h3 {
  font-size: 2.3rem;
  font-weight: 500;
}

.Footer_logoBox__2hjVt {
  text-align: center;
  margin-bottom: 7rem;
}

.Footer_logo__u68Lg {
  width: 15rem;
  height: auto;
}

.Footer_navigation__2GtR6 {
  margin-top: 3rem;
  border-top: 2px solid rgb(168, 15, 15);
  margin-left: 1rem;
}

.Footer_list__3gm8n {
  list-style: none;
  width: 400px;
  padding-right: 0.1rem;
  padding-top: 0.2rem;
  display: inline-block;
  
  font-weight: 200;
}

.Footer_item__1L6Be {
  transition: all 0.2s;
}

.Footer_item__1L6Be:hover {
 
}

.Footer_item__1L6Be:not(:last-child) {
  margin-right: 1.5rem;
}

.Footer_item__1L6Be a {
  color: rgb(221, 221, 221);
  text-decoration: none;
  transition: all 0.1s;
}

.Footer_item__1L6Be a:hover {
  color: #c4161c;
  font-size: 2rem;
  font-weight: 400;
}
.Footer_copyright__2jFw5 {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.Footer_ulist__1s8MI ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 400px;
  font-weight: 200;
}

.Footer_ulist__1s8MI ul li {
  display: flex;
  justify-content: space-between;
}

.Footer_row__3Fkqc {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Footer_iframeMedia__WgLsX {
  margin: 5rem 0;
}


@media (max-width: 52em) {
  .Footer_list__3gm8n {
    width: 265px;
  }

  .Footer_ulist__1s8MI{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .Footer_ulist__1s8MI ul {
    width: 265px;
  }
  .Footer_row__3Fkqc {
    
    font-size: 1.8rem;
  }
  .Footer_row__3Fkqc > div {
    display: flex;
    
    justify-content: center;
  }
 
  .Footer_footer__2x5FD h3 {
    font-size: 2.2rem;
    font-weight: 500;
  }
}

@media(max-width: 562px) {
   .Footer_row__3Fkqc > div:not(:first-child) {
    margin-top: 5rem;
  }
}


.Footer_iframeContent__1yrly {
  display: flex;
  justify-content: center;
  width: 100%;
}

/*

0 - 600px       phone
600 - 900px     tablet portrait
900 - 1200px    tablet landscape
1200 - 1800     normal styles
1800px +        big desktop

breakpoints
600px bo 1em = 16px => 
- phone         max-width 37.5em
- tab-port      max-width 56.25em
- tab-land      max-width 75em
- big-desktop   min-width 112.5em

*/

*,
*::after,
*::before {
  margin: 0 ;
  padding: 0 ;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(249, 249, 249);
  color: #777;
}
.Navigation_Navigation__3E0dk {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;

  display: none;
}
@media (max-width: 75em) {
  .Navigation_Navigation__3E0dk {
    display: initial;
  }
}
@media (max-width: 56.25em) {
  .Navigation_Navigation__3E0dk {
    display: initial;
  }
}
@media (min-width: 112.5em) {
  .Navigation_Navigation__3E0dk {
    display: none;
  }
}

.Navigation_checkbox__kNYPr {
  display: none;
}

.Navigation_label__IOheh {
  background-color: rgb(28, 34, 47);
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.3);
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 2px;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 20000;
  text-align: center;
  cursor: pointer;
}

.Navigation_background__3y5RT {
  height: 0rem;
  width: 0rem;
  border-radius: 1px;
  position: fixed;
  top: 0rem;
  right: 0rem;
  background-image: radial-gradient(rgb(28, 34, 47), rgb(28, 34, 47));
  opacity: 0;
  z-index: 10000;
  transition: opacity 0.5s cubic-bezier(0.86, 0, 0.07, 0.55),
    -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 0.55);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 0.55),
    opacity 0.5s cubic-bezier(0.86, 0, 0.07, 0.55);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 0.55),
    opacity 0.5s cubic-bezier(0.86, 0, 0.07, 0.55),
    -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 0.55);
}

.Navigation_container__etKmN {
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10001;
  background-image: inherit;
  opacity: 0;
  width: 0;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Navigation_container__etKmN > ul.Navigation_list__23YbP {
  display: none;
}

.Navigation_list__23YbP {
  list-style: none;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}

.Navigation_item__zu3I6 {
  margin: 1rem;
}

.Navigation_link__WPz6A:link,
.Navigation_link__WPz6A:visited {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;
}

.Navigation_link__WPz6A:link span,
.Navigation_link__WPz6A:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}

.Navigation_link__WPz6A:hover,
.Navigation_link__WPz6A:active,
link.Navigation_active__NYqb4 {
  background-position: 100%;
  color: #c4161c;
  -webkit-transform: translateX(1rem);
          transform: translateX(1rem);
}

.Navigation_dropdownList__Qq6bz > ul a {
  font-size: 1.6rem !important;
}

.Navigation_dropdownList__Qq6bz ul {
  list-style: none;
}

button.Navigation_navButton__1BUYk:hover + .Navigation_dropdownList__Qq6bz {
  background-color: #fff;
  display: none;
}

button.Navigation_navButton__1BUYk:focus {
  border: none;
}

.Navigation_navButton__1BUYk {
  display: inline-block;
  background-color: inherit;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 300;
  padding: 1.1rem 2rem;
  border: none;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.4s;
}

.Navigation_checkbox__kNYPr:checked ~ .Navigation_background__3y5RT {
  z-index: 1000;
  opacity: 1;
  height: 100vh;
  width: 100%;
}

.Navigation_checkbox__kNYPr:checked ~ .Navigation_container__etKmN {
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.Navigation_checkbox__kNYPr:checked ~ .Navigation_container__etKmN > ul.Navigation_list__23YbP {
  display: inline-block;
}

.Navigation_icon__VmGTS {
  width: 3rem;
  position: relative;
  margin-top: 2.2rem;
}

.Navigation_icon__VmGTS,
.Navigation_icon__VmGTS::before,
.Navigation_icon__VmGTS::after {
  height: 0.25rem;
  background-color: #fff;
  display: inline-block;
}

.Navigation_icon__VmGTS::after {
  width: 1.8rem;
}

.Navigation_icon__VmGTS::before {
  width: 2.6rem;
}

.Navigation_icon__VmGTS::before,
.Navigation_icon__VmGTS::after {
  content: "";
  position: absolute;
  right: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 0.2s;
}

.Navigation_icon__VmGTS::before {
  top: -0.8rem;
}

.Navigation_icon__VmGTS::after {
  top: 0.8rem;
}

.Navigation_label__IOheh:hover .Navigation_icon__VmGTS::before {
  top: -0.9rem;
}

.Navigation_label__IOheh:hover .Navigation_icon__VmGTS::after {
  top: 0.9rem;
}

.Navigation_checkbox__kNYPr:checked + .Navigation_label__IOheh .Navigation_icon__VmGTS {
  background-color: transparent;
}

.Navigation_checkbox__kNYPr:checked + .Navigation_label__IOheh .Navigation_icon__VmGTS::before {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  width: 2.8rem;
  top: 0;
}

.Navigation_checkbox__kNYPr:checked + .Navigation_label__IOheh .Navigation_icon__VmGTS::after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  width: 2.8rem;
  top: 0;
}

.Navigation_logo__2Pgi9 {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.DesktopNavigation_DesktopNavigation__no3oU {
  background-image: linear-gradient(
    to right,
    rgba(28, 34, 47, 1) 100%,
    transparent 100%
  );
  height: 60px;
  position: absolute;
  top: 0rem;
  left: 0;
  width: 100%;
  z-index: 122;
  list-style: none;
  position: fixed;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -2rem 5rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 75em) {
  .DesktopNavigation_DesktopNavigation__no3oU {
    display: none;
  }
}
@media (max-width: 56.25em) {
  .DesktopNavigation_DesktopNavigation__no3oU {
    display: none;
  }
}
@media (min-width: 112.5em) {
  .DesktopNavigation_DesktopNavigation__no3oU {
    display: flex;
  }
}

ul.DesktopNavigation_list__iUr4h {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.DesktopNavigation_list__iUr4h li {
  display: flex;
  line-height: 60px;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  padding: 0 2rem;
}

.DesktopNavigation_list__iUr4h li a {
  color: rgb(230, 230, 230);
  text-decoration: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  height: 100%;
  padding: 0 1rem;
  transition: border-bottom 0.01s ease-in-out;
}

.DesktopNavigation_list__iUr4h li a.DesktopNavigation_active__3v539 {
  font-weight: 600;
  border-bottom: 3px solid rgba(196, 22, 28, 1);
  color: rgba(196, 22, 28, 1);
}

.DesktopNavigation_list__iUr4h li a:hover {
  border-bottom: 3px solid rgba(196, 22, 28, 1);
}

.DesktopNavigation_dropdown__151r0 a.DesktopNavigation_firstChild__2wawK {
  border-bottom: 1px solid rgb(28, 34, 47);
}

.DesktopNavigation_dropdownContent__3MKiz {
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.2rem;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  position: absolute;
  background-color: rgb(28, 34, 47);
  
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 0.2s;
}

.DesktopNavigation_dropdownContent__3MKiz a:not(:first-child) {
  margin-left: 2rem;
}

.DesktopNavigation_dropdown__151r0 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.2s;
}

.DesktopNavigation_dropdown__151r0:hover .DesktopNavigation_dropdownContent__3MKiz {
  opacity: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  position: absolute;
  left:0rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 0.2s;
}

.DesktopNavigation_logoBox__3BiK7 {
  position: absolute;
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  right: 0;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.DesktopNavigation_logoBox__3BiK7 img {
  width: 8rem;
}
.Header_Header__13pEs {
  position: relative;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  background-color: rgb(28, 34, 47);
}

.Header_background__1YD5l {
  display: none;

  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(/static/media/flamenco_mobile_new.8ce06eeb.jpg);
  background-image: linear-gradient(
      to top,
      rgb(47, 56, 77),
      rgba(255, 255, 255, 0.205)
    ),
    url(/static/media/flamenco_mobile_new.8ce06eeb.jpg);
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

@media (max-width: 56.25em) {
  .Header_background__1YD5l {
    display: flex;
  }
}
.Header_bgVideoContent__2RbRH {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 56.25em) {
  .Header_bgdesktop__Ftd6_ {
    display: none;
  }
}
.Header_text__2eO0f {
  position: absolute;
  min-width: 270px;
  left: 50%;
  top: 43%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2.6rem;
  overflow: hidden;
  color: #fff;
}
.Header_text__2eO0f p:nth-child(2) {
  font-size: 4rem;
  font-weight: 600;
}
.Header_text__2eO0f p:last-child {
  margin-top: 5rem;
  font-size: 2.7rem;
}

@media (max-width: 56.25em) {
  .Header_text__2eO0f {
    font-size: 1.5rem;
    text-align: center;
    word-wrap: break-word;

    z-index: 100;
  }

  .Header_text__2eO0f p:last-child {
    margin-top: 4rem;
    font-size: 2rem;
  }

  .Header_text__2eO0f p:nth-child(2) {
    font-size: 3rem;
    font-weight: 600;
  }
}

/* Scroll Button */

.Header_demo__2y7AE a {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  z-index: 100;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  background-color: transparent;

  border-radius: 50%;

  width: 10.9rem;
  height: 10.9rem;

  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);

  transition: opacity 0.3s;
}
.Header_section10__1bO4T a:hover span {
  opacity: 0.5;
}

.Header_section10__1bO4T a span {
  position: absolute;
  top: 35px;
  left: 50%;

  width: 30px;
  height: 45px;
  margin-left: -15px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
  transition: opacity 0.3s;
}

.Header_section10__1bO4T a span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: Header_sdb10__3h-aK 2s infinite;
  animation: Header_sdb10__3h-aK 2s infinite;
  box-sizing: border-box;
}

.Header_section10__1bO4T a span:hover {
  opacity: 0.5;
}

.Header_section10__1bO4T a span:hover ~ .Header_arrow__WO-4D {
  opacity: 0.5;
}

.Header_arrow__WO-4D {
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 100;
  font-size: 2rem;
  z-index: 30;
}

@-webkit-keyframes Header_sdb10__3h-aK {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Header_sdb10__3h-aK {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Header_bgdesktop__Ftd6_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgb(28, 34, 47) 4%,
    transparent 60%);
  /* ), url("../../../assets/images/homepageHeaderDesktop.jpg"); */
 background-position: center;
  background-size: cover;
  z-index: 1;
}

.Header_slider__1HDbv {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}
.HeaderSlider_Slider__3FF9b {
    width: 100%;
    height: 100vh;
}

.HeaderSlider_slider__2yJks div {
    width: 100%;
    height: 100vh;
}

.HeaderSlider_image__1LhnV {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.About_About__1e5fP {
  position: relative;
  display: flex;
  height: auto;
  background-color: rgb(28, 34, 47);
  
  color: rgb(240, 240, 240);
}

.About_containerLeft__3MplZ {
  padding: 10rem 10rem;
  width: 55%;
  height: auto;
  
  /* background-color: rgb(249, 249, 249); */
  /* box-shadow: 1rem 2rem 5rem rgba(0, 0, 0, 0.15); */
}

.About_containerLeft__3MplZ h2 {
  font-family: "Poppins", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 3rem;
  padding-left: 1rem;
  border-left: 2px solid rgb(240, 240, 240);
}

.About_containerLeft__3MplZ p {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 1.55rem;
  text-align: justify;
}

.About_highlighted__Noz3m {
  letter-spacing: 1px;
  font-weight: 600;
  display: inline-block;
  padding: 2px;
  margin: 2px;
  transition: all 0.2s;
}

.About_highlighted__Noz3m:hover {
  color: #c4161c;
  border-bottom: 2px solid #c4161c;
}

.About_containerRight__c_tGm {
  position: relative;
  width: 65%;
  padding: 35rem 10rem;
  /* box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.1) inset; */
}
.About_containerRight__c_tGm img {
  width: 50%;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.3);
  outline: .6rem solid #fff;
  outline-offset: 1rem;
  -webkit-filter: contrast(1.1);
          filter: contrast(1.1);
}

.About_highlighted__Noz3m a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.About_highlighted__Noz3m a:hover {
  color: #c4161c;
}

.About_image2__J4XVX { 
  position: absolute;
  bottom: 6rem;
  right: 10rem;
  transition: all 0.2s;
}

.About_image1__2iRed {
  position: absolute;
  top: 6rem;
  left: 10rem;
  transition: all 0.2s;
}
.About_image1__2iRed:hover,
  .About_image2__J4XVX:hover {
    -webkit-transform: scale(1.05) translateY(-0.5rem);
            transform: scale(1.05) translateY(-0.5rem);
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    z-index: 20;
  }

@media (max-width: 80em) {
  .About_About__1e5fP {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .About_containerLeft__3MplZ {
    width: 100%;
    padding: 12rem;
  }
  .About_containerRight__c_tGm {
    width: 100%;
  }

  .About_containerRight__c_tGm img {
    width: 45%;
  }
}
@media (max-width: 56.25em) {
  .About_containerLeft__3MplZ {
    padding: 3.2rem;
  }
  .About_containerRight__c_tGm {
    position: relative;
    transition: all 0.2s;
  }
  .About_containerRight__c_tGm img {
    width: 55%;
  }

  .About_image1__2iRed {
    top: 11rem;
    position: absolute;
    left: 7rem;
    height: auto;
    transition: all 0.2s;
  }
  .About_image2__J4XVX {
    bottom: 11rem;
    position: absolute;
    right: 7rem;
    transition: all 0.2s;
  }

  .About_image1__2iRed:hover,
  .About_image2__J4XVX:hover {
    -webkit-transform: scale(1.1) translateY(-0.5rem);
            transform: scale(1.1) translateY(-0.5rem);
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    z-index: 20;
  }
}

@media (max-width: 32.5em) {
  .About_containerRight__c_tGm {
    position: relative;
    transition: all 0.2s;
  }
  .About_image1__2iRed,
  .About_image2__J4XVX {
    width: 67% !important;
  }
}

@media (min-width: 112.5em) {
}

.Dance_Dance__VvoOU {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
  width: 100%;
}

.Dance_Dance__VvoOU:hover {
  background-color: rgba(211, 0, 0, 0.8);
}

.Dance_danceName__15zUb a {
  text-align: justify;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 300;
  background-color: transparent;
  color: rgb(61, 74, 102);
  text-decoration: none;
  transition: all 0.2s;
}

.Dance_danceName__15zUb:hover a {
  color: #fff;
}

.Dances_Dances__10xnI {
  background-color: rgb(238, 238, 238);
  position: relative;
  height: auto;
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
}

.Dances_background__2LGCw {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  z-index: -1;
}
.Dances_Dances__10xnI ul {
  list-style: none;
}

.Dances_section__1sojX ul {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.Dances_Dances__10xnI h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 5rem;
  padding-top: 10rem;
  font-size: 5.5rem;
  color: rgba(196, 22, 28, 1);
}

.Dances_Dances__10xnI h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 4rem;
  color: rgb(28, 34, 47);
}

.Dances_section__1sojX {
  position: relative;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  padding-top: 7rem;
  font-family: "Poppins", sans-serif;

  z-index: 1;
}

.Dances_section__1sojX .Dances_listBox__2BCtG:first-child {
  grid-area: 1 / 3 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:last-child {
  grid-area: 1 / 14 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(2) {
  grid-area: 1 / 7 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(3) {
  grid-area: 1 / 11 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(4) {
  grid-area: 1 / 11 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(5) {
  grid-area: 1 / 14 / span 4 / span 3;
}
.Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(6) {
  grid-area: 8 / 10 / span 4 / span 3;
}

@media (max-width: 75em) {
  .Dances_section__1sojX {
    padding-top: 8rem;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:first-child {
    grid-area: 1 / 2 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(2) {
    grid-area: 1 / 6 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(3) {
    grid-area: 1 / 10 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(4) {
    grid-area: 1 / 10 / span 4 / span 3;
  }
}
@media (max-width: 56.25em) {
  .Dances_section__1sojX {
    padding-top: 7rem;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(5) {
    grid-area: 16 / 6 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(4) {
    grid-area: 18 / 6 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:nth-child(3) {
    grid-area: 10 / 6 / span 4 / span 3;
  }
  .Dances_section__1sojX .Dances_listBox__2BCtG:last-child {
    grid-area: 10 / 6 / span 4 / span 3;
  }
}
@media (max-width: 550px) {
  .Dances_section__1sojX {
    display: flex;
    flex-direction: column;
    padding: 7rem 9rem;
  }
  .Dances_listBox__2BCtG:not(:first-child) {
    margin-top: 5rem;
  }
}

@media (max-width: 450px) {
  .Dances_section__1sojX {
    padding: 7rem;
  }
}

.Dances_image__aWxNx {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 36rem;
  align-self: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(196, 22, 28, 1);
}

.Dances_image__aWxNx img {
  object-fit: cover;
  object-position: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-filter: contrast(1.2);
          filter: contrast(1.2);
}

.Dances_danceName__2zksX {
  color: rgba(196, 22, 28, 1);
  text-align: center;
  background-color: rgb(248, 248, 248);
  font-size: 2.4rem;
  border-left: 1px solid rgba(196, 22, 28, 1);
  border-right: 1px solid rgba(196, 22, 28, 1);
}

.Dances_listBox__2BCtG {
  box-shadow: 0rem 1rem 5rem rgba(255, 0, 8, 0.185);
  border-radius: 3px;
  display: flex;
  text-transform: capitalize;
  flex-direction: column;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  z-index: 2;
}

.Dances_imageContainer__2CTOw {
  display: flex;
  justify-content: center;
}

.Dances_solo__1D_bH,
.Dances_dancesInPair__2xCph,
.Dances_kids__3ciuX,
.Dances_danceFormation__2owRK,
.Dances_saturday__3rgdh,
.Dances_morning__1F7zF {
  border-left: 1px solid rgba(196, 22, 28, 1);
  border-right: 1px solid rgba(196, 22, 28, 1);
  border-bottom: 1px solid rgba(196, 22, 28, 1);
  background-color: rgb(248, 248, 248);

  box-shadow: 1rem 1rem 4rem rgba(0, 0, 0, 0.2);
}

.Instructors_section__2kruH {
  width: auto;
  height: 100%;
  text-align: center;
  position: relative;
  background-color: rgba(196, 22, 28, 1);
  box-shadow: 0rem 0rem 8rem rgba(0, 0, 0, 0.5) inset;
  padding-bottom: 7rem;
}

.Instructors_text__3cgJJ {
  position: relative;
  text-align: center;
  padding: 13rem 0;
  padding-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 5.5rem;
  font-weight: 500;
  color: #fff;
  z-index: 2;
}

.Instructors_background__3ew51 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    209deg,
    rgb(28, 34, 47) 49.5rem,
    transparent 49.5rem
  );
  z-index: 1;
  display: none;
}

.Instructors_instructorSlider__1QFBh {
  padding: 5rem;
}

.Card_Card__hljbl {
  height: 52rem;
  width: auto;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
 
  box-shadow: 0 0.8rem 3rem rgba(0, 0, 0, 0.4);
  position: relative;
  transition: all 0.2s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 2;
  margin: 6rem 4rem;
}

.Card_name__15qCA {
  color: rgb(230, 230, 230);
  position: absolute;
  bottom: 0;
  background-color: rgb(28, 34, 47);
  left: 0;
  width: 3rem;
  height: 100%;
  text-transform: capitalize;
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  font-size: 1.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  line-height: 3rem;
  z-index: 4;
}

.Card_name__15qCA h2 {
  font-weight: 500;
}

.Card_image__1YtUU {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-position-x: 40%;
  overflow: hidden;
  z-index: 3;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-filter: contrast(1.1) grayscale(100%) brightness(80%);
          filter: contrast(1.1) grayscale(100%) brightness(80%);

  transition: all 0.2s;
}

.Card_content__3u-tG {
  padding: 1rem;
  position: relative;
  top: 5rem;
  text-align: left;
  word-wrap: break-word;
  display: none;
  transition: all 0.2s;
  z-index: 20;
  font-size: 1.5rem;
  font-weight: 200;
  text-decoration: none;
}

.Card_Card__hljbl:hover .Card_content__3u-tG {
  display: flex;
  color: white;
  padding: 2rem;
}

.Card_Card__hljbl:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Card_Card__hljbl:hover .Card_image__1YtUU {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}


.HomePage_HomePage__1ieca {
  display: block;
}

.HomePage_mediaContainer__XJSs5 {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem;
  justify-content: space-evenly;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 0rem 5rem rgba(0, 0, 0, 0.165) ;
}
.HomePage_iframeMedia__21xkj {
  margin: 1rem;
  border: 1px solid black;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
}

@media (max-width: 56.25em) {
  .HomePage_mediaContainer__XJSs5 {
    padding: 4rem;
  }
}

.Header_header__1gW35 {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__1brAF {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__1x2F2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__2IooY {
  margin-top: 12vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 5rem;
  height: auto;
}

.Header_textDance__1we7Q {
  font-weight: 500;
}

@media (max-width: 40em) {
  .Header_text__2IooY {
    margin-top: 8rem;
  }
}

.Image_Image__3WPSF {
  border-radius: 3px;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.3);
  margin-left: 8rem;
}

.Image_container__EJ_tI {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1px;
  padding: 3rem;
  border: 3px solid rgb(28, 34, 47);
}

.Information_Information__2XMvA {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border-bottom: 1px solid #000;
  z-index: -2;
  width: 100%;
}

.Information_Information__2XMvA h3 {
  padding-bottom: 3rem;
  font-size: 4rem;
  font-weight: 400;
}

.Information_Information__2XMvA p {
  font-weight: 200;
}
.Information_containerMiddle__QZiUr {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  padding: 14rem;
  padding-bottom: 5rem;
  text-align: center;
}

.Information_mainText__9L2xV {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.Information_mainText__9L2xV p:not(:first-child) {
  margin-top: 1rem;
}

.Information_highlighted__2qxRW {
  color: #e4171e;
}

.Information_mainContent__2j5jr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  padding: 10rem;
}

.Information_image__2gTJo {
  width: 100%;
  padding: 0rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Information_image__2gTJo > * {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 5rem;
}

/*blockquote.information*/

blockquote.Information_information__35mY6 {
  display: block;
  background: #fff;
  padding: 15px 20px 15px 45px;
  margin: 0 0 20px;
  position: relative;
  width: 70%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #666;
  box-shadow: 2px 2px 15px #ccc;

  border-left-style: solid;
  border-left-width: 15px;
  border-right-style: solid;
  border-right-width: 2px;
}

blockquote.Information_information__35mY6::before {
  content: "\201C";
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #999;
  position: absolute;
  left: 10px;
  top: 5px;
}

blockquote.Information_information__35mY6::after {
  content: "";
}

blockquote.Information_information__35mY6 a:hover {
  color: #666;
}

blockquote.Information_information__35mY6 em {
  font-style: italic;
}

blockquote.Information_information__35mY6.Information_danceflowColor__1FGNP {
  border-left-color: #e4171e;
  border-right-color: #da4453;
}
@media (max-width: 75em) {
  .Information_containerMiddle__QZiUr {
    padding: 0rem 14rem;
    padding-top: 15rem;
    padding-bottom: 5rem;
  }
  blockquote.Information_information__35mY6 {
    width: 100%;
  }
  blockquote.Information_information__35mY6 p {
    word-wrap: break-word;
  }
  .Information_mainContent__2j5jr {
    flex-direction: column;
    width: 100%;
  }
  .Information_image__2gTJo {
    width: 100%;
  }
}
@media (max-width: 56.25em) {
  .Information_containerMiddle__QZiUr {
    padding: 7rem 7rem;
  }
  .Information_mainText__9L2xV {
    font-size: 2.1rem;
    font-weight: 600;
  }
  .Information_mainContent__2j5jr {
    flex-direction: column;
    padding: 0%;
  }
  .Information_image__2gTJo {
    width: 100%;
    padding: 0;
  }
  blockquote.Information_information__35mY6 {
    width: 100%;
    margin-top: 5rem;
  }
  blockquote.Information_information__35mY6 p {
    word-wrap: break-word;
    font-size: 1.8rem;
    font-weight: 300;
  }
  blockquote.Information_information__35mY6 h3.Information_quoteHeader__3acf- {
    font-size: 3rem;
    font-weight: 500;
  }
}

.Header_header__SbLmp {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__21n0i {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__2txsX {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__2QXQt {
  margin-top: 12vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 5rem;
  height: auto;
}

.Header_textDance__2Ip4W {
  font-weight: 500;
}

@media (max-width: 40em) {
  .Header_text__2QXQt {
    margin-top: 8rem;
  }
}

.BatchParty_BatchParty__3cxpk {
    margin: 0 auto;
  }
  
  .BatchParty_BatchParty__3cxpk h1 {
    text-align: center;
  }
  
.BatchParty_mainContent__1hi8a {
  font-family: "Poppins", sans-serif;
  padding: 5% 15%;
  display: flex;
  flex-wrap: wrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  justify-content: center;
  text-align: left;
  font-size: 1.5rem;
}

.BatchParty_mainContent__1hi8a h3 {
 color:rgba(196, 22, 28, 1);
 font-size: 3.3rem;
 text-align: center;
}

.BatchParty_mainContent__1hi8a p {
  font-size: 2.4rem;
  margin-top: 2rem;
  display: inline-block;
}


.BatchParty_image__1vAMY {
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.BatchParty_image__1vAMY img {
  width: 600px;
  height: auto;
  box-shadow: 1rem 1rem 3rem rgba(0,0,0,.4);
  outline: 1rem solid black;
  outline-offset: 2.5rem
}


@media (max-width: 75em) {
  .BatchParty_image__1vAMY img {
    width: 500px;
  }
}
@media (max-width: 56.25em) {
  .BatchParty_image__1vAMY img {
    width: 100%;
  }
}

.Header_header__2tfMl {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__dw9ne {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__3St6h {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__a0UDO {
  margin-top: 12vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 5rem;
  height: auto;
}

.Header_textDance__2vZNi {
  font-weight: 500;
}

@media (max-width: 40em) {
  .Header_text__a0UDO {
    margin-top: 8rem;
  }
}

.DanceShows_DanceShows__36Wbg {
    margin: 0 auto;
  }
  
  .DanceShows_DanceShows__36Wbg h1 {
    text-align: center;
  }
.DanceShows_mainSection__290Wv {
  padding: 10% 14%;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
}

.DanceShows_mainSection__290Wv p:first-child {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 6rem;
}

.DanceShows_mainSection__290Wv p:not(:first-child) {
  margin-bottom: 4rem;
}

.DanceShows_highlighted__1x2TM {
  color: rgba(196, 22, 28, 1);
}

.DanceShows_mainSection__290Wv .DanceShows_images__Je5Pf {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.DanceShows_images__Je5Pf div {
  width: 350px;
  margin-top: 5rem;
}

.DanceShows_images__Je5Pf div:not(:first-child){
  margin-left: 2rem;
 
}

.DanceShows_images__Je5Pf div img {
  box-shadow: 2px 2px 15px #ccc; 
  border-radius: 3px;
  outline: solid 0.5rem black;
  outline-offset: 1rem;
}
  
.Header_header__30xjW {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__2iAQS {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__3-tfl {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__10L5M {
  margin-top: 12vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 4.5rem;
  height: auto;
}

.Header_textDance__3enNg {
  font-weight: 500;
}

@media (max-width: 40em) {
  .Header_text__10L5M {
    margin-top: 1.2rem;
  }
}

.IndividualClasses_IndividualClasses__28kkO {
    margin: 0 auto;
  }
  
  .IndividualClasses_IndividualClasses__28kkO h1 {
    text-align: center;
  }
  .IndividualClasses_mainSection__18-62 {
    text-align: center;
    padding: 10% 14%;
    font-size: 2.5rem;
    font-family: "Poppins", sans-serif;
  }
  
  .IndividualClasses_mainSection__18-62 p:first-child {
  
    font-size: 3rem;
    margin-bottom: 6rem;
  }
  
  .IndividualClasses_mainSection__18-62 p:not(:first-child) {
    margin-bottom: 4rem;
  }
  
  .IndividualClasses_highlighted__3MmWq {
    color: rgba(196, 22, 28, 1);
  }
.Header_header__1wh3Y {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__1gUoV {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__CXho_ {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__uxcGv {
  margin-top: 11vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 7rem;
  height: auto;
}

.Header_textDance__37hUS {
  font-weight: 500;
}

.Form_heading__2CT8W {
  font-size: 2rem;
  padding: 2rem 0;
}

.Form_headingSecondary__31fOH {
  font-weight: 200;
}

/* rgb(28, 34, 47); */

.Form_book__179UC {
  background-color: rgb(28, 34, 47);
  background-size: cover;
  border-radius: 2rem;
  box-shadow: 0rem 1rem 4rem rgba(28, 34, 47, 0.767);
}



.Form_bookForm__3t6IF {
  width: 100%;
  padding: 2rem 6rem;
  position: relative;
  overflow-y: auto;
}



.Form_formGroup__1mWKd {
  margin-bottom: 2rem;
}

.Form_formInput__1prfG {
  font-size: 1.5rem;
  color: inherit;
  font-family: inherit;
  padding: 1rem 1rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.4s;
}



.Form_formInput__1prfG::-webkit-input-placeholder {
  color: rgb(49, 49, 49);
}
.Form_formInput__1prfG:focus:invalid {
  border-bottom: 3px solid #ff5638;
}
.Form_formInput__1prfG:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}

.Form_formLabel__1Xe0E {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
  font-weight: 300;
  margin-left: 0.3rem;
  display: block;
  transition: all 0.3s;
}

.Form_formInput__1prfG:placeholder-shown + .Form_formLabel__1Xe0E {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
          transform: translateY(-4rem);
}

.Form_btnContainer__1lsvb {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.Form_btn__1Xu0Z,
.Form_btn__1Xu0Z:link,
.Form_btn__1Xu0Z:visited {
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  text-decoration: none;
  padding: 1rem 3rem;
  display: inline-block;
  border-radius: 2px;
  transition: all 0.2s;
  position: absolute;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.Form_btn__1Xu0Z:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.Form_btn__1Xu0Z:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
          transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.Form_btn__1Xu0Z:active,
.Form_btn__1Xu0Z:focus {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border: none;
}

.Form_btn__1Xu0Z::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.2s;
}
.Form_btnRed__3nJ5x {
  font-weight: 500;
  border: 2px solid rgb(243, 38, 45);
  background-color: transparent;
  color: rgb(243, 38, 45);
}
.Form_btnRed__3nJ5x::after {
  background-color: rgb(243, 38, 45);
  color: rgb(28, 34, 47);
  z-index: -1;
}

.Form_btnRed__3nJ5x:hover {
  background-color: rgb(243, 38, 45);
  color: rgb(28, 34, 47);
}

p.Form_instructions__3UxAS {
  color: rgba(255, 255, 255, 1);
  position: absolute;
  font-weight: 200;
  font-size: 1.7rem;
  top: -1rem;
  display: block;
  right: 0;
  width: 70%;
  text-align: center;
}




@media (max-width: 56.25em) {
  .Form_formInput__1prfG {
    width: 100%;
  }
}

@media (max-width: 37.5em) {
  .Form_book__179UC {
    box-shadow: none;
    border-radius: 0;
  }

  .Form_bookForm__3t6IF {
    padding: 2rem 3rem;
  }
}
.Contact_Contact__1GNbo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Poppins", sans-serif;
}

.Contact_mainSection__1kS6z {
  display: flex;
  flex-wrap: wrap;
}

.Contact_map__1aw27 {
  background-image: linear-gradient(
    to left top,
    rgb(70, 89, 129),
    rgb(28, 34, 47)
  );
  width: 100%;
  order: 1;
}

.Contact_text__2w34z {
  width: 100%;
  background-color: rgb(249, 249, 249);
  z-index: 1;
  order: 0;
}

.Contact_form__3s-Fi {
  width: 100%;
  height: auto;
  background-color: #c4161c;
  box-shadow: 0rem 0rem 8rem rgba(0, 0, 0, 0.3) inset;
}

.Contact_text__2w34z {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Contact_text__2w34z ul {
  list-style-type: none;
}

.Contact_text__2w34z ul {
  padding: 16rem 0;
}

@media (max-width: 75em) {
  .Contact_text__2w34z {
    justify-content: center;
  }
  .Contact_text__2w34z ul {
    padding: 8rem 4rem;
    text-align: center;
  }
}

.Contact_text__2w34z ul li:first-child {
  font-size: 3rem;
  padding-bottom: 3rem;
}

.Contact_text__2w34z ul:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Contact_text__2w34z ul:nth-child(2) li {
  display: flex;
  justify-content: space-between;
}

.Contact_redText__24s9Y {
  color: #c4161c;
}

@media (max-width: 56.25em) {
  .Contact_logo__3C72h {
    top: 5%;
    left: 5%;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
}

.Contact_sectionBook__3vL6x {
  color: #fff;
  padding: 15rem 28rem;
}
@media (max-width: 75em) {
  .Contact_sectionBook__3vL6x {
    padding: 8rem 10rem;
  }
}
@media (max-width: 56.25em) {
  .Contact_sectionBook__3vL6x {
    padding: 0;
  }
}

.Header_Header__2OlZ2 {
  height: 100vh;
  background-color: rgb(209, 209, 209);
  display: flex;
  flex-wrap: wrap;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative;
}

.Header_containerLeft__Y9b2H {
  position: relative;
  width: 20%;
  background-color: rgb(28, 34, 47);
}

.Header_mainText__3FtMV {
  position: absolute;

  top: 5rem;
  left: 10rem;
  font-size: 4rem;
  text-transform: capitalize;
  color: #fff;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  padding-left: 1rem;
  padding-top: 2rem;
  line-height: 16rem;
  z-index: 10;
}

h1 {
  font-weight: 500;
  -webkit-animation-name: Header_moveInLeft__2Y2OY;
          animation-name: Header_moveInLeft__2Y2OY;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.Header_containerMiddle__2csx5 {
  width: 50%;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.Header_containerRight__3w0eJ {
  width: 30%;
  background-color: #c4161c;
  color: #fff;
  padding: 1rem;
  align-self: stretch;
  display: flex;
}

.Header_description__1wTPd {
  height: 100%;
  width: 100%;
  text-align: left;
  line-height: 1.5;
  padding: 2rem;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
}

.Header_description__1wTPd div {
  align-self: flex-end;
  font-weight: 200;

  -webkit-animation-name: Header_moveInRight__1op9A;

          animation-name: Header_moveInRight__1op9A;
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.Header_navi__y6Lz2 {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  line-height: 17rem;
  display: flex;
  align-content: stretch;

  z-index: 10;
}

.Header_arrowLeft__XYynF {
  background-color: rgba(0, 0, 0, 0.1) !important;
  width: 50%;
  height: 15rem;
  font-size: 13rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s;
  z-index: 10;
}

.Header_arrowRight__3Hsr8 {
  background-color: rgba(0, 0, 0, 0.1) !important;
  width: 50%;
  height: 15rem;
  border-left: 1px solid black;
  cursor: pointer;
  font-size: 13rem;
  text-align: center;
  z-index: 10;
}

.Header_arrowLeft__XYynF:hover {
  color: rgb(28, 34, 47);
  background-color: rgb(196, 22, 28) !important;
}

.Header_arrowRight__3Hsr8:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

@-webkit-keyframes Header_moveInLeft__2Y2OY {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@keyframes Header_moveInLeft__2Y2OY {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@-webkit-keyframes Header_moveInRight__1op9A {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  70% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  90% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@keyframes Header_moveInRight__1op9A {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  70% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  90% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 80em) {
  .Header_Header__2OlZ2 {
    height: auto;
  }
  .Header_mainText__3FtMV {
    top: 20rem;
    left: 1rem;
    font-size: 3.6rem;
  }
  .Header_containerLeft__Y9b2H {
    width: 30%;
  }
  .Header_containerMiddle__2csx5 {
    width: 70%;
    background-position: center;
  }
  .Header_containerRight__3w0eJ {
    width: 100%;
  }
}
@media (max-width: 56.25em) {
  .Header_Header__2OlZ2 {
    height: auto;
  }
  .Header_mainText__3FtMV {
    
    border: none;
    top: 65rem;
    height: 10rem;
    line-height: 5rem;
    color: #fff;
    left: 0;
    width: 100%;
    font-size: 2rem;
    word-wrap: break-word;
    letter-spacing: normal;
  }
  .Header_containerLeft__Y9b2H {
    position: relative;
    width: 100%;
    height: 20vh;

    order: 1;
    z-index: 100;
  }
  .Header_containerMiddle__2csx5 {
    order: 0;
    height: 80vh;
    width: 100%;
    background-position: center;
  }
  .Header_containerRight__3w0eJ {
    width: 100%;
    order: 2;
  }
  .Header_navi__y6Lz2 {
    line-height: 22vh;
    height: 100%;
  }
  .Header_arrowLeft__XYynF {
    height: 100%;
  }
  .Header_arrowRight__3Hsr8 {
    height: 100%;
  }
}

.Dances_Dances__15ED_ {
  background-color: rgb(247, 247, 247);
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.Dances_mainSection__1EG2J {
  width: 100%;
  height: auto;
  background-color: rgb(221, 221, 221);
  display: flex;
}

.Dances_video__1jL7Q {
  padding: 1rem;
  width: 50%;
}

.Dances_content__2jYTV {
  width: 50%;
}

.Dances_nearestClasses__hPFeD {
  padding: 2rem 0;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  height: 100%;
  text-align: center;
}
.Dances_nearestClasses__hPFeD p:first-child {
  text-align: center;
  color: #c4161c;
}
.Dances_schedule__3ELpg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Dances_classes__1fRWd {
  margin: 1.2rem;
  padding: 1.2rem;
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.2s;
  border: 1px solid #c4161c;
  border-radius: 3px;
}

.Dances_classes__1fRWd:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Dances_classes__1fRWd p:not(:first-child) {
  border-top: 1px solid rgba(196, 22, 28, 0.2);
}
.Dances_contentHeader__2oI01 {
  font-size: 3rem;
  color: rgb(28, 34, 47) !important;
}

@media (max-width: 80em) {
  .Dances_mainSection__1EG2J {
    flex-wrap: wrap;
  }
  .Dances_video__1jL7Q {
    width: 100%;
  }
  .Dances_content__2jYTV {
    width: 100%;
  }
}

.HeaderImage_HeaderImage__PQErp {
  width: 35%;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.HeaderImage_navi__3pvjA {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 31rem;
  height: 15rem;
  line-height: 17rem;
  display: flex;
  align-content: stretch;
}

.HeaderImage_arrowLeft__1D4xC {
  background-color: rgb(28, 34, 47) !important;
  width: 15rem;
  height: 15rem;
  font-size: 13rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s;
}

.HeaderImage_arrowRight__5EY9O {
  background-color: rgb(28, 34, 47) !important;
  width: 16rem;
  height: 15rem;
  border-left: 1px solid black;
  cursor: pointer;
  font-size: 13rem;
  text-align: center;
}

.HeaderImage_arrowLeft__1D4xC:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

.HeaderImage_arrowRight__5EY9O:hover {
  color: rgb(28, 34, 47);
  background-color: #c4161c !important;
}

@media (max-width: 75em) {
  .HeaderImage_HeaderImage__PQErp {
    width: 40%;
  }
}
@media (max-width: 56.25em) {
  .HeaderImage_HeaderImage__PQErp {
    width: 100%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

.HeaderDescription_HeaderDescription__54UwP {
  width: 65%;
  padding-top: 3rem;
  color: rgb(249, 249, 249);
  background-color: #c4161c;
  
  letter-spacing: 1px;
  font-weight: 200;
  display: flex;
}

.HeaderDescription_content__3hHJy {
  padding: 4rem;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0.8rem 5rem rgba(0, 0, 0, 0.2);
  display: flex;
  text-align: left;
}

.HeaderDescription_content__3hHJy p {
  align-self: flex-start;
  -webkit-animation: HeaderDescription_moveInLeft__1iIZp 1.2s ease-out;
          animation: HeaderDescription_moveInLeft__1iIZp 1.2s ease-out;
}

@-webkit-keyframes HeaderDescription_moveInLeft__1iIZp {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@keyframes HeaderDescription_moveInLeft__1iIZp {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 90em){
  .HeaderDescription_HeaderDescription__54UwP{
    font-size: 1.4rem;
  }
}

@media (max-width: 75em) {
  .HeaderDescription_HeaderDescription__54UwP {
    width: 60%;
    font-size: 1.3rem;
    padding-top: 0;
  }
}
@media (max-width: 56.25em) {
  .HeaderDescription_HeaderDescription__54UwP {
    width: 100%;
    order: 1;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

.HeaderDances_HeaderDances__3OOIl {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  line-height: 7.3;
  letter-spacing: 2px;
  color: #fff;
  background-color: rgb(28, 34, 47);
  transition: all 0.2;
}

.HeaderDances_HeaderDances__3OOIl p {
  transition: all 0.2;
}

.HeaderDances_HeaderDances__3OOIl p:hover {
  color: #c4161c;

}
@media (max-width: 75em) {
  .HeaderDances_HeaderDances__3OOIl {
    -webkit-writing-mode: horizontal-tb;
            writing-mode: horizontal-tb;
    width: 100%;
    order: 2;
    border-bottom: 1px solid black;
  }
  .HeaderDances_HeaderDances__3OOIl p:hover {
    border: none;
  }
}
@media (max-width: 56.25em) {
  .HeaderDances_HeaderDances__3OOIl {
    flex-wrap: wrap;
    font-size: 1.2rem;
  }
}

.Instructors_Instructors__3kIsA {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(249, 249, 249);
  height: auto;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.Instructors_headerName__Q_uPN {
  text-shadow: 0 0 .8rem rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 30%;
  left: 65%;
  font-size: 8rem;
  color: #fff;
  z-index: 20;
  -webkit-animation: Instructors_moveInRight__24bBc 0.8s ease-out;
          animation: Instructors_moveInRight__24bBc 0.8s ease-out;
}

@-webkit-keyframes Instructors_moveInRight__24bBc {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@keyframes Instructors_moveInRight__24bBc {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transition: translate(0);
  }
}

@media (max-width: 75em) {
  .Instructors_Instructors__3kIsA {
    flex-wrap: wrap;
  }
  .Instructors_headerName__Q_uPN {
    left: 60%;
    top: 52%;
    font-size: 7rem;
  }
}
@media (max-width: 56.25em) {
  .Instructors_headerName__Q_uPN {
    font-size: 7rem;
    left: 10%;
    bottom: unset;
    top: 1%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

.Header_header__14i-8 {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__3nPgI {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__n1kUt {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__1sHfj {
  margin-top: 11vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 7rem;
  height: auto;
}

.Header_textDance__1N-JX {
  font-weight: 500;
}

.Card_Card__RmCXE {
  margin: 14rem 1rem;
  width: 30rem;
  height: 40rem;
  border-radius: 3px;
  border: 1px solid #c4161c;
  position: relative;
  font-family: inherit;
  box-shadow: 0.8rem 1rem 4rem rgba(0, 0, 0, 0.25);
}

@media (max-width: 75em) {
  .Card_Card__RmCXE {
    margin: 7rem 1rem;
  }
}
@media (max-width: 56.25em) {
  .Card_Card__RmCXE {
    margin: 2rem 1rem;
  }
}
@media (min-width: 112.5em) {
}

.Card_background__325p0 {
  background-color: #c4161c;
  width: 100%;
  height: 115%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: ellipse(180% 71% at 50% 0%);
  clip-path: ellipse(180% 71% at 50% 0%);
}

.Card_cardContent__3VCA0 p {
  font-size: 2rem;
}

.Card_btn__2XPhe {
  position: absolute;
  bottom: 0;
  width: 100%;
  border: none;
  outline: none;
  height: 7rem;
  font-family: inherit;
  font-size: 2rem;
  background-color: rgb(249, 249, 249);
  color: rgb(28, 34, 47);
  text-transform: lowercase;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 0.08s;
}

.Card_btn__2XPhe:hover {
  color: #c4161c;
}

.Card_btn__2XPhe p {
  transition: all 0.2s;
}

.Card_btn__2XPhe:hover p {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Card_cardContent__3VCA0 {
  position: absolute;
  top: 15%;
  left: 0;
  z-index: 1;
  padding: 3rem;
  color: #fff;
  font-size: 2.7rem;
  line-height: 3.5rem;
  text-align: center;
  width: 100%;
  height: auto;
}

.Prices_Prices__nA3h2 {
  margin: 0 auto;
  background-color: rgb(249, 249, 249);
  font-family: "Poppins", sans-serif;
}

.Prices_Prices__nA3h2 h1 {
  text-align: center;
}

.Prices_Prices__nA3h2 h3 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
}

.Prices_Prices__nA3h2 h2 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}
.Prices_Prices__nA3h2 h4 {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 7rem;
}

.Prices_mainHeading__2ZRtC {
  padding: 10rem 0;
}

.Prices_paddingy__16-yD {
  padding-left: 10rem;
  padding-right: 10rem;
}

.Prices_carnets__1Tr-7 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Prices_danceWithInstructor__1FLbp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;
}

.Prices_roomRental__12wQo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;

}

.Prices_proAmator__2M4xg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 10rem;

}

.Prices_partners__1421N {
  padding: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Prices_partners__1421N h3 {
  padding: 5rem;
  width: 100%;
}

.Prices_partners__1421N p {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 2rem;
}

.Prices_partners__1421N img {
  width: auto;
  height: 12rem;
}

.Prices_price__I8vjq {
  color: #c4161c;
}

.Prices_studentHall__3H3Ew {
  padding: 10rem;
}
.Schedule_Schedule__2Yl8p {
  margin: 0 auto;
}

.Schedule_Schedule__2Yl8p h1 {
  text-align: center;
}

.Header_header__2Q2IJ {
  position: relative;
  height: 30vh;
  background-color: #c4161c;
  background-size: cover;
  background-position: top;
  box-shadow: 0 -1rem 8rem rgba(0, 0, 0, 0.2) inset;
}

.Header_logo__17VFx {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Header_textBox__2Zq8I {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Header_text__1ygix {
  margin-top: 11vh;
  width: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 7rem;
  height: auto;
}

.Header_textDance__3PVFD {
  font-weight: 500;
}

.MainContent_MainContent__1t9K_ {
  padding: 7rem 0.5rem;
  width: 100%;
}

.MainContent_schedule__116sg {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.2);
}
.MainContent_scheduleHeader___OjAw {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.MainContent_scheduleHours__1ZZe9 {
  background-color: rgb(28, 34, 47);
  display: flex;
  justify-content: center;
  width: 5.5rem;
  color: #fff;
}

.MainContent_scheduleDays__2AFvf {
  background-color: rgb(28, 34, 47);
  display: flex;
  width: 100%;
  color: #fff;
}

.MainContent_scheduleDays__2AFvf > div {
  min-width: 16.666666667%;
  text-align: center;
}

.MainContent_scheduleColumn__1jv5x {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.MainContent_scheduleCell__5BLN2 {
  min-height: 31.5rem;
  display: flex;
  flex-wrap: wrap;
}

.MainContent_scheduleColumn__1jv5x:nth-child(2) {
  color: #c4161c;
  line-height: 31rem;
  text-align: center;
  display: flex;
  width: 5.5rem;
  font-size: 2rem;
  background-color: #fff;
  border-left: 2px solid #e1e1e1;
}
.MainContent_scheduleColumn__1jv5x:nth-child(2) p {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
  width: calc((100% - 5.5rem)/6);
  font-size: 1px;
}

@media (max-width: 1710px) {
  .MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1520px) {
  .MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1370px) {
  .MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

@media (max-width: 1260px) {
  .MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}
@media (max-width: 1040px) {
  .MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) {
    width: calc((100% - 5.5rem)/6);
    font-size: 1px;
  }
}

.MainContent_scheduleColumn__1jv5x:not(:nth-child(2)) .MainContent_scheduleCell__5BLN2 {
  padding: 3px;
}

.MainContent_scheduleHeader___OjAw {
  text-align: center;
  font-size: 2rem;
}

.MainContent_scheduleHeader___OjAw .MainContent_scheduleHours__1ZZe9 {
  align-items: center;
  text-align: center;
  width: 5.5rem;
}

.MainContent_schedule__116sg {
  background-color: white;
  border: 1px solid #e1e1e1;
}

.MainContent_scheduleHeader___OjAw > div {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
}

.MainContent_scheduleCell__5BLN2 {
  border: 0.1px solid #e1e1e1;
}

@media (min-width: 1326px) {
  .MainContent_scheduleMobile__AZbfe {
    display: none;
  }
}

@media (max-width: 1325px) {
  .MainContent_schedule__116sg {
    display: none;
  }
  .MainContent_scheduleMobile__AZbfe {
    display: flex;
    flex-direction: column;
  }
}
/*mobile/tab shedule*/

.MainContent_scheduleMobileDays__3Lr_a {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0rem 6rem rgba(0, 0, 0, 0.2);
}
.MainContent_scheduleMobileDay__TJGek {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  color: #c4161c;
  padding: 5rem 0rem;
}
.MainContent_scheduleMobileListContainer__EyRVT {
  width: 100%;
  
}
.MainContent_scheduleMobileList__21UIT {
  list-style: none;
}
.MainContent_scheduleMobileListItem__1x0dE {
  width: 100% !important;
}

.Card_Card__qPr6d {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  color: #fff;
  font-size: 1.2rem;
  height: 100%;
  background-color: inherit;
  position: relative;
  transition: all 0.2s;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s;
  width: 100%;
}

.Card_Card__qPr6d:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.Card_Card__qPr6d > *:first-child {
  border-left: none;
}

.Card_informations__2ScF0 {
  text-transform: capitalize;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0.7rem 25rem rgba(255, 255, 255, 0.65) inset;
  -webkit-filter: brightness(110%), contrast(120%);
          filter: brightness(110%), contrast(120%);
  background-color: inherit;
  position: absolute;
  word-wrap: break-word;
  padding: 1.4rem 0.2rem;
  font-size: 1rem;
  width: 100%;
  left: 0rem;
  top: -7rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
  height: 6.5rem;
  z-index: 1;
  transition: opacity 0.3s;
}
.Card_Card__qPr6d:hover .Card_informations__2ScF0 {
  visibility: visible;
  opacity: 1;
}
.Card_Card__qPr6d a {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  width: 100%;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
}
.Card_btn__2OZFn {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  width: 100%;
  height: 23%;
  line-height: 2.7rem;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.35s;
}
.Card_content__21j7m {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}
.Card_content__21j7m > * {
  transition: all 0.2s;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Card_btn__2OZFn a,
.Card_btn__2OZFn a:active,
.Card_btn__2OZFn a:link {
  color: #fff;
  border: none;
}
.Card_Card__qPr6d .Card_btn__2OZFn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.Card_Card__qPr6d .Card_content__21j7m div:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.Card_Card__qPr6d:hover .Card_btn__2OZFn {
  background-color: rgba(255, 255, 255, 0.2);
}
.Card_Card__qPr6d:hover .Card_link__1kSHJ {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.Card_Card__qPr6d .Card_link__1kSHJ:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.Card_danceName__32z13 {
  line-height: auto;
  font-size: 1.3rem;
  word-wrap: break-word;
}
@media (max-width: 1490px) {
  .Card_danceName__32z13 {
    font-size: 1.15rem;
  }
  .Card_btn__2OZFn {
    font-size: 1.1rem;
  }
}
.Card_smallContainer__3b8AV {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  background-color: inherit;
}

.solo {
  background-color: #f80c12 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(248, 12, 20, 0.2);
}

.inPairs {
  background-color: #1193bb !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(17, 147, 187, 0.2);

}

.danceFormation {
  background-color: #69d025 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(105, 208, 37, 0.2);

}

.kids {
  background-color: #fd61c1 !important;
  border-radius: 4px;
  width: 47.5%;
  margin: 2px;
  height: 150px;
  box-shadow: 0rem 0rem 2rem 1rem rgba(253, 97, 193,0.2);

}

@media (max-width: 1325px) {
  .danceFormation,
  .inPairs,
  .solo,
  .kids {
    border-radius: 3px;
    margin: 0;
    height: 100%;
  }
}

.CardMobile_CardMobile__RCVU5 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 1.8rem;
  height: 100%;
  background-color: inherit;
  position: relative;
  word-break: keep-all;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s;
  width: 100%;
  margin: 1rem 0;
}
@media (max-width: 810px) {
  .CardMobile_CardMobile__RCVU5 {
    font-size: 1.5rem;
  }
}
.CardMobile_informations__1wfQ- {
  width: 30%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  padding: 1rem;
}
.CardMobile_CardMobile__RCVU5 a {
  font-family: "Poppins", sans-serif;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  width: 15%;
  height: 100%;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  text-align: center;
  align-self: center;
}
.CardMobile_btn__c9MUA {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 1.3rem;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.35s;
}
.CardMobile_content__3iXuE {
  width: 70%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.CardMobile_content__3iXuE div:first-child {
  width: 48%;
  align-self: center;
}
.CardMobile_content__3iXuE div:nth-child(2) {
  width: 22%;
  line-height: 5rem;
  align-self: center;
}
.CardMobile_content__3iXuE div:nth-child(3) {
  width: 30%;
  align-self: center;
}
.CardMobile_content__3iXuE div:nth-child(4) {
  width: 25%;
  align-self: center;
}

.CardMobile_content__3iXuE > div.CardMobile_link__yONH_ {
  padding: 1rem;
  align-self: center;
}

.CardMobile_content__3iXuE > div:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 655px) {
  .CardMobile_content__3iXuE div p {
    display: inline-block;
    vertical-align: middle;
  }
}

@media(max-width:518px) {
  .CardMobile_content__3iXuE div p {
    font-size: 1.3rem;
  }
  .CardMobile_informations__1wfQ- {
    width: 30%;
  }
  .CardMobile_content__3iXuE div:nth-child(3) {
    width: 35%;
  }
}

.CardMobile_btn__c9MUA a,
.CardMobile_btn__c9MUA a:active,
.CardMobile_btn__c9MUA a:link {
  color: #fff;
  border: none;
}
.CardMobile_CardMobile__RCVU5 .CardMobile_btn__c9MUA:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.CardMobile_CardMobile__RCVU5:hover .CardMobile_btn__c9MUA {
  background-color: rgba(255, 255, 255, 0.2);
}

.CardMobile_CardMobile__RCVU5:hover .CardMobile_link__yONH_ {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.CardMobile_CardMobile__RCVU5 .CardMobile_link__yONH_:hover {
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Popup_popup__12L2W {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: stretch;
  top: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 0.9);
  z-index: 999999;
  opacity: 1;
  transition: all 0.5s;
  
}
@media (max-width: 56.25em) {
  .Popup_popup__12L2W {
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .Popup_popup__12L2W {
    height: 100%;
    display: flex;
  }
}
.Popup_popupContent__2YgD_ {
  width: 100%;
}

.Popup_popupClose__11Ska {
  position: fixed;
  color: #999;
  background-color: transparent;
  width: 6rem;
  height: 6rem;
  right: 1rem;
  top: 1rem;
  border: none;
  font-size: 5rem;
  text-decoration: none;
  transition: all 0.2s;
  line-height: 1;
  z-index: 12345699;
}



.Popup_popupClose__11Ska:hover {
  color: rgb(243, 38, 45);
}
.Popup_background__3ECPf {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Popup_form__BrWIc {

  font-family: "Poppins", sans-serif;
  color: white;
  position: fixed;
  overflow-y: auto;
  scrollbar-base-color: rgb(28, 34, 47);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
  z-index: 2;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(243, 38, 45); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(28, 34, 47); 
}

@media (max-width: 80.25em) {
  .Popup_form__BrWIc {
    top: 50%;
    width: 90%;
    height: 90%;
  }
}
@media (max-width: 37.5em) {
  .Popup_form__BrWIc {
    top: 50%;
    width: 100%;
    height: 100%;
  }
}

.Legend_Legend__21qP6 {
  padding: 6rem;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 500px) {
  .Legend_Legend__21qP6 {
    padding: 2rem;
  }
}

.Legend_dances__1MFhg {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.Legend_dances__1MFhg li {
  margin-top: 3rem;
  margin-right: 1rem;
}

.Legend_dances__1MFhg li:first-child,
.Legend_levels__23Mhp li:first-child {
  width: 100%;
}

.Legend_levels__23Mhp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  list-style: none;
  text-align: justify;
}

.Legend_levels__23Mhp li {
  width: 100%;
}

.Legend_solo__3mGrg {
  color: #fff;
  padding: 1.2rem;
  background: #f80c12;
}

.Legend_inPairs__16Cmr {
  color: #fff;
  padding: 1.2rem;
  background: #1193bb;
}

.Legend_danceFormation__3V7SP {
  color: #fff;
  padding: 1.2rem;
  background: #69d025;
}

.Legend_kids__2R6NG {
  color: #fff;
  padding: 1.2rem;
  background: #fd61c1;
}

.Legend_offer__TPnJJ {
  text-align: center;
  padding-top: 15px;
}

@media (max-width: 1164px) {
  .Legend_Legend__21qP6 {
    font-size: 1.9rem;
  }
}

.NotFound_NotFound__-ylg6 {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgb(28, 34, 47);
  box-shadow: 0rem 0rem 35rem rgba(0,0,0,0.5) inset;
}

.NotFound_container__3dZQB {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.NotFound_NotFound__-ylg6 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4.5rem;
  color: #c4161c;
  text-align: center;
}

.WeddingDance_WeddingDance__3WPsT {
    width: 100%;
    height: 100%;
    display: flex;
    color: rgb(88, 88, 88);
    overflow: hidden !important;
}

.WeddingDance_headerText__1FPdD {
    width: 100%;
    color: #a71539;
    font-family: 'Arima Madurai', cursive;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-color: #a71539;
            text-decoration-color: #a71539;
    text-decoration-thickness: 4px;
    text-underline-offset: 10px;
    font-size: 10rem;
    font-weight: 200;
    text-align: center;
}

.WeddingDance_headerTextParagraph__6ixEO {
    font-family: Poppins !important;
    width: 100%;
    color: rgb(88, 88, 88);
    font-size: 3rem;
    font-family: 'Great Vibes', cursive;
    text-align: center;
}

.WeddingDance_header__141ib {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
}

/* rgbFFF */

.WeddingDance_headerFlower__2nNZt {
    position: absolute;
    overflow: hidden;
    top: 7rem;
    right: 9rem;
    -webkit-transform: rotate(295deg);
            transform: rotate(295deg);
    width: 100px;
}

.WeddingDance_headerFlowerTwo__1YXsh {
    overflow: hidden;
    position: absolute;
    top: 20rem;
    left: -10rem;
    -webkit-transform: rotate(160deg);
            transform: rotate(160deg);
    width: 500px;
}

.WeddingDance_headerPhoto__2y-jb {
    position: absolute;
    top: 10rem;
    right: 15rem;
    width: 200px;
    height: 300px;
    outline: #fff 5px solid;
    outline-offset: 10px;
    object-fit: cover;
    -webkit-filter: grayscale(1) contrast(1.5);
            filter: grayscale(1) contrast(1.5);

}

.WeddingDance_headerContent__1yVOv {
    width: 100%;
    margin: 10rem;
    padding: 10rem;
    flex-wrap: wrap;
    background-color: rgb(255, 232, 226);
    box-shadow: 0px 0px 140px rgb(255, 232, 226) ;

}

.WeddingDance_headerContent__1yVOv p {
    font-family: 'Great Vibes', cursive;

}

.WeddingDance_headerSecondaryText__2mtw2 {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
}

.WeddingDance_headerSecondaryTextParagraph__32Cmc {
    padding: 3rem;
}

.WeddingDance_buttonContainer__34msa {
    padding: 1rem;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WeddingDance_weddingButton__1BRKD {
    padding: 1.2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #a71539;
    background-color: transparent;
    border: none;
    border: 1px solid #a71539;
    transition: all 0.15s ease-in;
}

.WeddingDance_weddingButton__1BRKD:hover {

    color: #c4161c;
    border: none;
    border: 1px solid #c4161c;
    box-shadow: 0px 0px 20px rgba(255, 57, 57, 0.2) ;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.WeddingDance_weddingButtonText__3JqLD {
    font-family: Poppins !important;

    padding-left: 0.7rem;
    font-size: 14px;
}
.panel-enter {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
    opacity: 0.01;
    transition: opacity 700ms ease-in, -webkit-transform 1350ms ease-out;
    transition: transform 1350ms ease-out, opacity 700ms ease-in;
    transition: transform 1350ms ease-out, opacity 700ms ease-in, -webkit-transform 1350ms ease-out;
  }
  
  .panel-enter-active {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
  
 
.panel-exit {
    -webkit-transform: translate(0, 0) ;
            transform: translate(0, 0) ;
    opacity: 1;
  }
  
  .panel-exit-active { 
    -webkit-transform: translate(-100%, 0) ; 
            transform: translate(-100%, 0) ;
    opacity: 0;
    transition: opacity 700ms ease-out, -webkit-transform 1500ms ease-in;
    transition: transform 1500ms ease-in, opacity 700ms ease-out;
    transition: transform 1500ms ease-in, opacity 700ms ease-out, -webkit-transform 1500ms ease-in;
  }
