.Image {
  border-radius: 3px;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.3);
  margin-left: 8rem;
}

.container {
  width: fit-content;
  border-radius: 1px;
  padding: 3rem;
  border: 3px solid rgb(28, 34, 47);
}
