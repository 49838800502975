.WeddingDance {
    width: 100%;
    height: 100%;
    display: flex;
    color: rgb(88, 88, 88);
    overflow: hidden !important;
}

.headerText {
    width: 100%;
    color: #a71539;
    font-family: 'Arima Madurai', cursive;
    text-decoration-line: underline;
    text-decoration-color: #a71539;
    text-decoration-thickness: 4px;
    text-underline-offset: 10px;
    font-size: 10rem;
    font-weight: 200;
    text-align: center;
}

.headerTextParagraph {
    font-family: Poppins !important;
    width: 100%;
    color: rgb(88, 88, 88);
    font-size: 3rem;
    font-family: 'Great Vibes', cursive;
    text-align: center;
}

.header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
}

/* rgbFFF */

.headerFlower {
    position: absolute;
    overflow: hidden;
    top: 7rem;
    right: 9rem;
    transform: rotate(295deg);
    width: 100px;
}

.headerFlowerTwo {
    overflow: hidden;
    position: absolute;
    top: 20rem;
    left: -10rem;
    transform: rotate(160deg);
    width: 500px;
}

.headerPhoto {
    position: absolute;
    top: 10rem;
    right: 15rem;
    width: 200px;
    height: 300px;
    outline: #fff 5px solid;
    outline-offset: 10px;
    object-fit: cover;
    filter: grayscale(1) contrast(1.5);

}

.headerContent {
    width: 100%;
    margin: 10rem;
    padding: 10rem;
    flex-wrap: wrap;
    background-color: rgb(255, 232, 226);
    box-shadow: 0px 0px 140px rgb(255, 232, 226) ;

}

.headerContent p {
    font-family: 'Great Vibes', cursive;

}

.headerSecondaryText {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
}

.headerSecondaryTextParagraph {
    padding: 3rem;
}

.buttonContainer {
    padding: 1rem;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.weddingButton {
    padding: 1.2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #a71539;
    background-color: transparent;
    border: none;
    border: 1px solid #a71539;
    transition: all 0.15s ease-in;
}

.weddingButton:hover {

    color: #c4161c;
    border: none;
    border: 1px solid #c4161c;
    box-shadow: 0px 0px 20px rgba(255, 57, 57, 0.2) ;
    transform: scale(1.05);
}

.weddingButtonText {
    font-family: Poppins !important;

    padding-left: 0.7rem;
    font-size: 14px;
}