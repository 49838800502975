.Dance {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
  width: 100%;
}

.Dance:hover {
  background-color: rgba(211, 0, 0, 0.8);
}

.danceName a {
  text-align: justify;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 300;
  background-color: transparent;
  color: rgb(61, 74, 102);
  text-decoration: none;
  transition: all 0.2s;
}

.danceName:hover a {
  color: #fff;
}
