.NotFound {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgb(28, 34, 47);
  box-shadow: 0rem 0rem 35rem rgba(0,0,0,0.5) inset;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NotFound h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4.5rem;
  color: #c4161c;
  text-align: center;
}
