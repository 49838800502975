.Information {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border-bottom: 1px solid #000;
  z-index: -2;
  width: 100%;
}

.Information h3 {
  padding-bottom: 3rem;
  font-size: 4rem;
  font-weight: 400;
}

.Information p {
  font-weight: 200;
}
.containerMiddle {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  padding: 14rem;
  padding-bottom: 5rem;
  text-align: center;
}

.mainText {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.mainText p:not(:first-child) {
  margin-top: 1rem;
}

.highlighted {
  color: #e4171e;
}

.mainContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  padding: 10rem;
}

.image {
  width: 100%;
  padding: 0rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image > * {
  height: fit-content;
  margin: 5rem;
}

/*blockquote.information*/

blockquote.information {
  display: block;
  background: #fff;
  padding: 15px 20px 15px 45px;
  margin: 0 0 20px;
  position: relative;
  width: 70%;
  height: fit-content;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #666;
  box-shadow: 2px 2px 15px #ccc;

  border-left-style: solid;
  border-left-width: 15px;
  border-right-style: solid;
  border-right-width: 2px;
}

blockquote.information::before {
  content: "\201C";
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #999;
  position: absolute;
  left: 10px;
  top: 5px;
}

blockquote.information::after {
  content: "";
}

blockquote.information a:hover {
  color: #666;
}

blockquote.information em {
  font-style: italic;
}

blockquote.information.danceflowColor {
  border-left-color: #e4171e;
  border-right-color: #da4453;
}
@media (max-width: 75em) {
  .containerMiddle {
    padding: 0rem 14rem;
    padding-top: 15rem;
    padding-bottom: 5rem;
  }
  blockquote.information {
    width: 100%;
  }
  blockquote.information p {
    word-wrap: break-word;
  }
  .mainContent {
    flex-direction: column;
    width: 100%;
  }
  .image {
    width: 100%;
  }
}
@media (max-width: 56.25em) {
  .containerMiddle {
    padding: 7rem 7rem;
  }
  .mainText {
    font-size: 2.1rem;
    font-weight: 600;
  }
  .mainContent {
    flex-direction: column;
    padding: 0%;
  }
  .image {
    width: 100%;
    padding: 0;
  }
  blockquote.information {
    width: 100%;
    margin-top: 5rem;
  }
  blockquote.information p {
    word-wrap: break-word;
    font-size: 1.8rem;
    font-weight: 300;
  }
  blockquote.information h3.quoteHeader {
    font-size: 3rem;
    font-weight: 500;
  }
}
