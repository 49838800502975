.Dances {
  background-color: rgb(238, 238, 238);
  position: relative;
  height: auto;
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  z-index: -1;
}
.Dances ul {
  list-style: none;
}

.section ul {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.Dances h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 5rem;
  padding-top: 10rem;
  font-size: 5.5rem;
  color: rgba(196, 22, 28, 1);
}

.Dances h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 4rem;
  color: rgb(28, 34, 47);
}

.section {
  position: relative;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  padding-top: 7rem;
  font-family: "Poppins", sans-serif;

  z-index: 1;
}

.section .listBox:first-child {
  grid-area: 1 / 3 / span 4 / span 3;
}
.section .listBox:last-child {
  grid-area: 1 / 14 / span 4 / span 3;
}
.section .listBox:nth-child(2) {
  grid-area: 1 / 7 / span 4 / span 3;
}
.section .listBox:nth-child(3) {
  grid-area: 1 / 11 / span 4 / span 3;
}
.section .listBox:nth-child(4) {
  grid-area: 1 / 11 / span 4 / span 3;
}
.section .listBox:nth-child(5) {
  grid-area: 1 / 14 / span 4 / span 3;
}
.section .listBox:nth-child(6) {
  grid-area: 8 / 10 / span 4 / span 3;
}

@media (max-width: 75em) {
  .section {
    padding-top: 8rem;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  }
  .section .listBox:first-child {
    grid-area: 1 / 2 / span 4 / span 3;
  }
  .section .listBox:nth-child(2) {
    grid-area: 1 / 6 / span 4 / span 3;
  }
  .section .listBox:nth-child(3) {
    grid-area: 1 / 10 / span 4 / span 3;
  }
  .section .listBox:nth-child(4) {
    grid-area: 1 / 10 / span 4 / span 3;
  }
}
@media (max-width: 56.25em) {
  .section {
    padding-top: 7rem;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem;
  }
  .section .listBox:nth-child(5) {
    grid-area: 16 / 6 / span 4 / span 3;
  }
  .section .listBox:nth-child(4) {
    grid-area: 18 / 6 / span 4 / span 3;
  }
  .section .listBox:nth-child(3) {
    grid-area: 10 / 6 / span 4 / span 3;
  }
  .section .listBox:last-child {
    grid-area: 10 / 6 / span 4 / span 3;
  }
}
@media (max-width: 550px) {
  .section {
    display: flex;
    flex-direction: column;
    padding: 7rem 9rem;
  }
  .listBox:not(:first-child) {
    margin-top: 5rem;
  }
}

@media (max-width: 450px) {
  .section {
    padding: 7rem;
  }
}

.image {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 36rem;
  align-self: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(196, 22, 28, 1);
}

.image img {
  object-fit: cover;
  object-position: center;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  filter: contrast(1.2);
}

.danceName {
  color: rgba(196, 22, 28, 1);
  text-align: center;
  background-color: rgb(248, 248, 248);
  font-size: 2.4rem;
  border-left: 1px solid rgba(196, 22, 28, 1);
  border-right: 1px solid rgba(196, 22, 28, 1);
}

.listBox {
  box-shadow: 0rem 1rem 5rem rgba(255, 0, 8, 0.185);
  border-radius: 3px;
  display: flex;
  text-transform: capitalize;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  z-index: 2;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.solo,
.dancesInPair,
.kids,
.danceFormation,
.saturday,
.morning {
  border-left: 1px solid rgba(196, 22, 28, 1);
  border-right: 1px solid rgba(196, 22, 28, 1);
  border-bottom: 1px solid rgba(196, 22, 28, 1);
  background-color: rgb(248, 248, 248);

  box-shadow: 1rem 1rem 4rem rgba(0, 0, 0, 0.2);
}
