.Card {
  margin: 14rem 1rem;
  width: 30rem;
  height: 40rem;
  border-radius: 3px;
  border: 1px solid #c4161c;
  position: relative;
  font-family: inherit;
  box-shadow: 0.8rem 1rem 4rem rgba(0, 0, 0, 0.25);
}

@media (max-width: 75em) {
  .Card {
    margin: 7rem 1rem;
  }
}
@media (max-width: 56.25em) {
  .Card {
    margin: 2rem 1rem;
  }
}
@media (min-width: 112.5em) {
}

.background {
  background-color: #c4161c;
  width: 100%;
  height: 115%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: ellipse(180% 71% at 50% 0%);
  clip-path: ellipse(180% 71% at 50% 0%);
}

.cardContent p {
  font-size: 2rem;
}

.btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  border: none;
  outline: none;
  height: 7rem;
  font-family: inherit;
  font-size: 2rem;
  background-color: rgb(249, 249, 249);
  color: rgb(28, 34, 47);
  text-transform: lowercase;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 0.08s;
}

.btn:hover {
  color: #c4161c;
}

.btn p {
  transition: all 0.2s;
}

.btn:hover p {
  transform: scale(1.2);
}

.cardContent {
  position: absolute;
  top: 15%;
  left: 0;
  z-index: 1;
  padding: 3rem;
  color: #fff;
  font-size: 2.7rem;
  line-height: 3.5rem;
  text-align: center;
  width: 100%;
  height: auto;
}
