.Card {
  height: 52rem;
  width: auto;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
 
  box-shadow: 0 0.8rem 3rem rgba(0, 0, 0, 0.4);
  position: relative;
  transition: all 0.2s;
  backface-visibility: hidden;
  z-index: 2;
  margin: 6rem 4rem;
}

.name {
  color: rgb(230, 230, 230);
  position: absolute;
  bottom: 0;
  background-color: rgb(28, 34, 47);
  left: 0;
  width: 3rem;
  height: 100%;
  text-transform: capitalize;
  writing-mode: vertical-rl;
  font-size: 1.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  line-height: 3rem;
  z-index: 4;
}

.name h2 {
  font-weight: 500;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-position-x: 40%;
  overflow: hidden;
  z-index: 3;
  backface-visibility: hidden;
  filter: contrast(1.1) grayscale(100%) brightness(80%);

  transition: all 0.2s;
}

.content {
  padding: 1rem;
  position: relative;
  top: 5rem;
  text-align: left;
  word-wrap: break-word;
  display: none;
  transition: all 0.2s;
  z-index: 20;
  font-size: 1.5rem;
  font-weight: 200;
  text-decoration: none;
}

.Card:hover .content {
  display: flex;
  color: white;
  padding: 2rem;
}

.Card:hover {
  transform: scale(1.1);
}

.Card:hover .image {
  filter: grayscale(0);
}
