.HeaderDances {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  line-height: 7.3;
  letter-spacing: 2px;
  color: #fff;
  background-color: rgb(28, 34, 47);
  transition: all 0.2;
}

.HeaderDances p {
  transition: all 0.2;
}

.HeaderDances p:hover {
  color: #c4161c;

}
@media (max-width: 75em) {
  .HeaderDances {
    writing-mode: horizontal-tb;
    width: 100%;
    order: 2;
    border-bottom: 1px solid black;
  }
  .HeaderDances p:hover {
    border: none;
  }
}
@media (max-width: 56.25em) {
  .HeaderDances {
    flex-wrap: wrap;
    font-size: 1.2rem;
  }
}
